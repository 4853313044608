.modal-content-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.ant-modal-content {
  width: 360px !important;
  height: 480px !important;
  border-radius: 10px !important;
}

@media only screen and (max-width: 350px) {
  .ant-modal-content {
    width: 340px !important;
  }
}

.ant-modal-content .companies-modal {
  width: 300px !important;
  height: 350px !important;
  border-radius: 10px !important;
  margin-left: 8px;
}

.ant-modal-footer {
  display: none !important;
}

.ant-modal-header {
  display: none !important;
}

.option-text {
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-left: 10px;
}

.modal-links-container {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.modal-links-container_noneborder {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px;
  margin-top: 4px;
}

.modal-company-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 10px; */
  width: 100%;
  padding: 0px;
}

.modal-links-text {
  color: #1468ba;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.modal-links-text:hover {
  color: #1468ba;
}

.checkbox-container {
  width: 16px;
  height: 16px;
  box-shadow: none;
}

.tab-all-tag {
  position: relative;
}

.tab-all-tag-content {
  /* border: 1px solid #20C18D; */
  /* background-color: #20C18D; */
  padding: 0px;
  margin-top: -2px;
  position: absolute;
  right: -11px;
  width: 69px;
  top: -12px;
  height: 15px;
  text-align: center;
  color: white;
  font-size: 8px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.reviewed-all-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
  padding: 0px !important;
  margin: -1px !important;
}

.reviewed-all-content {
  width: 92% !important;
  /* border: 1px solid white !important; */
  box-shadow: 0 0 4px silver !important;
  padding: 0px !important;
  margin-top: 10px !important;
  background: white !important;
  border-radius: 4px !important;
  position: relative !important;
  border: none !important;
}

@media only screen and (max-width: 1023px) {
  .reviewed-all-content {
    width: 97% !important;
  }
}

@media only screen and (max-width: 550px) {
  .reviewed-all-content {
    width: 98.6% !important;
  }
}

.reviewed-all-tag {
  border: 1px solid #20c18d;
  position: absolute !important;
  right: 0px !important;
  background-color: #20c18d !important;
  padding: 0px;
  width: 69px;
  text-align: center;
  color: white;

  margin-top: -14px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  text-align: center;
  color: white;
  font-size: 10px !important;
  height: 15px;
}

.reviewed-all-tag-text {
  font-size: 9px !important;
  margin-left: -3px;
  margin-top: 0px;
}
