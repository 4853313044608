.pricing_card_main_ride {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  box-shadow: 5px 5px 10px #a6b4c8;
  border-radius: 20px;
  width: 180px;
}
.card_debose_section_wrap {
  border-radius: 15px;
  padding: 15px;
  padding-bottom: 0px;
  box-shadow: inset 5px 5px 10px #d3d3d3;
  margin-bottom: 30px;
}
.image_debose_part_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -5px;
  width: 130px;
  height: 70px;

  /*background-color: rgba(255, 0, 0, 0.5);*/
}
.imageLyft {
  width: 40% !important;
}
.imageDouble {
  width: 40% !important;
}
.card_debose_section_wrap .image_debose_part_wrapper img {
  width: 53%;
  /*padding-bottom: 10px;*/
}
.card_debose_section_wrap .image_debose_part_wrapper .para_debose_point {
  font-size: 16px;
  padding-top: 3px;
}

.content_section_pricing_card_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  /*background-co lor: red;*/
}
.content_section_pricing_card_wrapper .heading_content_pricing {
  font-size: 30px;
  font-weight: bold;
  font-family: Poppins;
  color: #ff9900;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content_section_pricing_card_wrapper .para_pricing_content {
  font-size: 12px;
  font-weight: 400;
  margin-top: -10px;
  font-family: Poppins;
}

.discount {
  del {
    color: #ff9900;
    text-decoration: none;
    position: relative;
    font-size: smaller;

    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 3px solid rgba(255, 0, 0, 0.8); /* Corrected to use proper rgba format */
      height: 12px;
      position: absolute;
      bottom: 30%; /* Adjusted for proper positioning */
      left: 0;
      transform: rotate(10deg); /* Adjust the angle as needed */
    }
  }
}

@media screen and (max-width: 713px) {
  .pricing_card_main_ride {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 5px 5px 10px #a6b4c8;
    border-radius: 20px;
    width: 180px;
  }
}

@media screen and (max-width: 548px) {
  .pricing_card_main_ride {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 5px 5px 10px #a6b4c8;
    border-radius: 20px;
    width: 180px;
  }

  .imageLyft {
    width: 40% !important;
  }
  .imageDouble {
    width: 40% !important;
  }
  .card_debose_section_wrap .image_debose_part_wrapper img {
    width: 53%;
    /*padding-bottom: 10px;*/
  }
  .card_debose_section_wrap .image_debose_part_wrapper .para_debose_point {
    font-size: 16px;
    padding-top: 3px;
  }
}
