.after-reviewing-header_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .chexs-text{
   color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 39px;
    letter-spacing: 0em;  
  }