.certificates-conatiner {
  background: #fff;
  width: 100%;
  height: calc(100vh - 66px);
  background-size: cover;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden !important;


}

/* @media only screen and  (min-width:1100px){
  .certificates-conatiner{
  height: 100%;
  }
  } */

.certificates-in-progress-text {
  padding: 15px;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.certificates-tag {
  color: #ff9900;
  text-align: center;
  padding: 4px;
  background-color: #fff5e5;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  width: 99.5%;

}

.certificates-main-container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

@media only screen and (max-width:450px) {
  .certificates-main-container {
    display: block;
  }
}

.back-text {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  /* position: fixed; */
  /* bottom: -2px; */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1468BA;
  /* margin-bottom: 13px; */

}



.progress-close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.home-text {
  margin-left: 3px;
  margin-right: 3px;
  -webkit-font-smoothing: antialiased;
}