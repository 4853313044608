.select-option-main-container {
  width: 100%;
  position: absolute;
  background: url('../../Assets/bg_img.png');
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: scroll;
}

.select-option-image-background-signin {
  position: relative;
  width: 100%;

  /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; */
}

.select-option-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.select-option-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

a {
  color: #fff;
}

.select-option-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 30px 0 0 0;
}

.logo-span-color {
  color: #ff9900;
}

.select-option-desp-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.155em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px !important;
}

.select-option-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
  ;
}

.select-option-form-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 12px;
  margin-top: 10px;

}


.select-option-intro-validation-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 0px;
  letter-spacing: 0.155em;
  color: #DADADA;

}

@media screen and (min-width: 768px) {
  .select-option-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}

.select-option-tabs {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 50px;
  border: 2px solid white;
  /* padding: 10px 60px; */
  width: 271px;
  height: 51px;
  text-align: center;
  cursor: pointer;
}

.select-option-tabs:hover {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 50px;
  border: 2px solid #FF7A00;
  width: 271px;
  height: 51px;
  text-align: center;
  background-color: #FF7A00;
  border-radius: 5px;
}

.select-option-tabs:focus {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 50px;
  border: 2px solid #FF7A00;
  width: 271px;
  height: 51px;
  text-align: center;
  background-color: #FF7A00;
  border-radius: 5px;
}

/* @media only screen and (max-width: 600px) {
    .ant-form-item-control-input {
      margin: auto !important;
      width: 500px !important;
    }
  }
  @media only screen and (max-width: 550px) {
    .ant-form-item-control-input {
      margin: none !important;
      width: 100% !important;
    }
  } */


.select-option-header {
  display: flex;
  justify-content: flex-start;
  width: 370px;
}

.select-option-header_container {
  align-self: center;
  display: flex;
  justify-content: center;
}