.centered-section-benifits {
  background-color: #f5f5f5;
}

.how-can-we-help-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding: 20px; */
  padding-top: 40px;
}

.how-can-we-help-section {
  background-color: #0050a3;
  max-height: 554px;
}

.how-can-we-help-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 20px;
}

.how-can-we-help-heading {
  width: 492px;
  font-size: 35px;
  font-weight: 500;
  line-height: 61.2px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.how-can-we-help-paragraph {
  width: 492px;
  font-size: 24px;
  font-weight: 380;
  line-height: 36px;
  text-align: left;
  color: #ffffff;
}

.how-can-we-help-button {
  margin-top: 20px;
}

.contact-us-button {
  display: inline-block;
  width: 212px;
  height: 50px;
  background-color: #ffffff;
  color: #438be3;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  text-decoration: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.contact-us-button:hover {
  color: #ffffff;
  background-color: #438be3;
  transition: 0.3s;
}

.how-can-we-help-image {
  display: flex;
  justify-content: flex-end; /* Align horizontally if needed */
  align-items: flex-end; /* Aligns the image to the bottom */
  height: 100%; /* Ensure the container takes up full height */
}

.analytics-image {
  max-width: 100%; /* Make sure the image is responsive */
  height: auto; /* Keeps the aspect ratio */
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
  .how-can-we-help-heading {
    font-size: 38px;
    font-weight: 400;
    line-height: 52px;
  }

  .how-can-we-help-paragraph {
    font-size: 22px;
    line-height: 32px;
  }

  .analytics-image {
    width: 80%;
  }

  .how-can-we-help-text {
    padding-left: 20px;
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .how-can-we-help-heading {
    font-size: 35px;
    font-weight: 400;
    line-height: 48px;
  }

  .how-can-we-help-paragraph {
    font-size: 20px;
    line-height: 30px;
  }

  .analytics-image {
    width: 75%;
  }
}

@media screen and (max-width: 767px) {
  .how-can-we-help-heading {
    font-size: 32px;
    font-weight: 400;
    line-height: 44px;
  }

  .how-can-we-help-paragraph {
    font-size: 18px;
    line-height: 28px;
  }

  .analytics-image {
    width: 85%;
  }

  .contact-us-button {
    font-size: 14px;
    width: 200px;
    height: 45px;
    line-height: 45px;
  }
}

@media screen and (max-width: 720px) {
  .how-can-we-help-heading {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
  }

  .how-can-we-help-paragraph {
    font-size: 16px;
    line-height: 24px;
  }

  .analytics-image {
    display: none;
  }

  .contact-us-button {
    font-size: 14px;
    width: 190px;
    height: 40px;
    line-height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .how-can-we-help-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .how-can-we-help-heading,
  .how-can-we-help-paragraph {
    text-align: center;
    width: 100%;
  }

  .how-can-we-help-heading {
    font-size: 26px;
    font-weight: 400;
    line-height: 35px;
  }

  .how-can-we-help-paragraph {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }

  .contact-us-button {
    margin: 20px auto;
    font-size: 14px;
    width: 180px;
    height: 40px;
    line-height: 40px;
  }

  .how-can-we-help-text {
    align-items: center;
    padding-left: 20px;
  }
}

@media screen and (max-width: 550px) {
  .how-can-we-help-heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .how-can-we-help-paragraph {
    font-size: 14px;
  }

  .contact-us-button {
    width: 170px;
    height: 35px;
    font-size: 13px;
    line-height: 35px;
  }
}

@media screen and (max-width: 500px) {
  .how-can-we-help-heading {
    font-size: 26px;
    line-height: 30px;
  }

  .how-can-we-help-text {
    padding-right: 20px;
  }

  .how-can-we-help-paragraph {
    font-size: 16px;
  }

  .contact-us-button {
    width: 160px;
    height: 35px;
    font-size: 12px;
    line-height: 35px;
  }
  .how-can-we-help-button {
    margin-top: 0px;
  }
}

@media screen and (max-width: 400px) {
  /* .how-can-we-help-heading {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  } */

  /* .how-can-we-help-paragraph {
    font-size: 12px;
    line-height: 20px;
  } */

  .contact-us-button {
    width: 150px;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
  }
}

@media screen and (max-width: 360px) {
  /* .how-can-we-help-heading {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .how-can-we-help-paragraph {
    font-size: 11px;
  } */

  .contact-us-button {
    width: 140px;
    height: 30px;
    font-size: 10px;
    line-height: 30px;
  }
}
