.manage-section-container {
  width: 100%;
}

.manage-section-inner {
  /* margin: auto; */
}
.manage-text-wrap-div {
}
.manage-section-divs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0;
}

.manage-section-divs > div {
  flex: 1 1 50%;
}
.manage-section-left-div {
  background-image: url("https://res.cloudinary.com/dgjordf6e/image/upload/v1726239991/landing-page-image-left_cbh7nc.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.manage-section-right-div {
  background-color: #0050a3;
  color: white;
  padding-top: 50px;
  padding-left: 100px;
  padding-bottom: 50px;
}
.manage-section-right-div-head {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.manage-section-right-div-para {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: capitalize;
  text-align: left;
}
.manage-section-read-more-btn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 40px;
  border-radius: 6px;
  background: #ffffff;
  color: #0050a3;
  border: none;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .manage-section-right-div {
    padding-top: 50px;
    padding-left: 70px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1030px) {
  .manage-section-right-div {
    padding-left: 30px;
  }
  .manage-section-right-div-head {
    font-size: 20px;
  }
  .manage-section-right-div-para {
    font-size: 11px;
  }
  .manage-section-read-more-btn {
    width: 120px;
    height: 30px;
  }
}

@media screen and (max-width: 767px) {
  .manage-section-divs > div {
    flex: 1 1 100%;
    margin: 10px 0;
  }
  .manage-section-left-div {
    display: none;
  }
  .manage-section-right-div {
    text-align: center;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .manage-section-right-div-head {
    font-size: 26px;
  }
  .manage-section-right-div-para {
    padding-left: 20px;
    padding-right: 10px;
    text-align: left;
    font-size: 14px;
  }
}
