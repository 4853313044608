.instruction-modal>.ant-modal-wrap {
  top: -100px !important;
}

.button-container {
  background: linear-gradient(90deg, rgb(255, 122, 0) 0%, rgb(255, 153, 0) 94.74%) !important;
  border-radius: 100px !important;
  border: 1px solid rgb(255, 116, 0) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center;
  color: rgb(255, 255, 255) !important;
  padding: 14px 60px 15px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  width: 80% !important;
  margin: 0px auto !important;
}

.inspection-capture_now-button {
  background: #FF7A00 !important;
  border-radius: 100px !important;
  border: 1px solid #FF7A00 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 10px 15px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  width: 260px !important;
  margin: 0px auto !important;
  height: 54px !important;
}

.input-img-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 50px;
}

.image-uploads {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-upload>input {
  display: none;
}

.instruction-modal-step-container {
  color: white;
  margin: auto;
  width: 100%;

}

.instruction-modal-step {
  margin-top: 10px;
  text-align: left;
  margin-left: 5px;
  font-size: 13px;
  width: 100%;
  line-height: 15px;
  color: #b2c7f0;
}

.ant-modal-close-x {
  color: white !important;
  margin-top: 35px;
}
.blurr--background {
  position: absolute;
  top: 18%;
  left: -5%;
  width: 110%;
  height: 100vh;
  object-fit: cover;
  backdrop-filter: blur(105px); 
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
  
}
.webCam--video{
   position: absolute;
   top: 75%;
  left: -5%;
  width: 110%; 
  /* height: 100%;  */
   object-fit: cover;
  z-index: 99999999px; 
  /* height: auto; */
 

}
/* .webcam-container {
  
  width: 100%;
  height: 200px;
  overflow: hidden;
} */
.webCam--image img{
  position: absolute;
   top: 75%;
  left: -2%;
  /* width: 110%; */
  /* height: 100vh; */
  /* object-fit: cover; */
  z-index: 99999999px;
  image-rendering: crisp-edges;
  /* image-rendering: pixelated; */

}
.video-save-recording{
  position: absolute;
  z-index: 999999;
  bottom: 0;
  margin-bottom: -76%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-left: 12%;
  /* text-align: center; */

 
 }
.video-recording{
  position:absolute;bottom:0;
  margin-bottom:-80%;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:30%;
 
 }
 .outer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid white;
}
.outer--circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid white;
  background-color: white;
 
}
.outer--circle:first-child {
  margin-right: 66px; /* Add right margin to separate the icons */
}
@media only screen and (max-width: 500px) {
  
  .video-recording{
    position:absolute;bottom:0;
    margin-bottom:-90%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:30%;
   
   }
   .video-save-recording{
    position: absolute;
  z-index: 999999;
  bottom: 0;
  margin-bottom: -80%;
  display: flex;
  justify-content: center;
  margin-left: 12%;

   
   }
}
@media only screen and (max-width: 700px) {
  .ant-modal-close-x {
    margin-top: 135px;
  }
}


.ant-modal-content {
  top: 35% !important;
  box-shadow: none !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  height: 100% !important;
  /* background-color: #12579b !important; */
  padding: 10px 20px !important;
  width: 100% !important;
  margin-top: 84%;
}

.ant-modal-body {
  border-radius: 30px;
  height: 100% !important;
  /* display: flex !important; */
  align-items: center !important;
  flex-direction: column !important;
}

.modal-inside-image {
  /* height: 100p% !important; */
  width: 100% !important;
  display: flex;
  flex-direction: column;
  height: 200px;
}
.modal-inside--image {
  /* height: 100p% !important; */
  width: 100% !important;
  display: flex;
  flex-direction: column;
  height: 200px;
}

@media only screen and (max-width: 600px) {
  .modal-inside-image {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .modal-inside-image {
    width: 100% !important;
  }
}

/* .ant-modal-wrap>div { */
  /* width: 100% !important;
  height: 100% !important; */
/* } */

.ant-modal-wrap.survay-modallsss>div {
  width: 100% !important;
  height: 650px !important;
}

.survay-modallsss {
  color: black !important;
}

.modal-video {
  width: 70% !important;
  height: 200px;

}

.modal-image {
  height: 200px;
  width: 100%;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  margin-top: 10px;
}
.modal--image {
  height: 200px;
  width: 45%;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  margin-top: 10px;
}
.modal-images {
  width: 100%;
  height: 88vh;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
}

.instruction-moadl-video {
  width: 100%;
  height: 50%;
}

.video-container {
  /* background-color: red !important; */
  height: 88vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.instruction-modalls .ant-modal-content {
  /* background: #266fb7; */
  margin-top: 10px;
  border-radius: 30px;
  background: transparent;
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #051532 115.19%), #191a1b; */
}

.ant-modal-wrap.instruction-modalls {
  background: linear-gradient(0deg, #0A305E, #0A305E), #191A1B;
}

.instruction-modalls .ant-modal-close {
  top: -90%;
  right: -12%;
}

@media screen and (max-width: 700px) {
  .instruction-modalls .ant-modal-close {
    top: -130%;
    right: -12%;
  }
}



@media screen and (min-width: 600px) {
  .button-container {
    width: 50% !important;
  }
}

@media screen and (max-width: 700px) {
  .ant-modal-content {
    width: 100%;
  }

  .modal-video {
    width: 80% !important;
  }
}

@media only screen and (max-width: 500px) {
  .modal-video {
    width: 100% !important;
  }

  .instruction-modalls .ant-modal-close {
    top: -125%;
    right: -12%;
  }
}

@media only screen and (max-width: 900px) {
  .modal-image {
    width: 100%;
  }

  .instruction-modal-step-container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .instruction-modal-step-container {
    width: 100%;
  }

  .instruction-modal-step {
    margin-left: 10px;
  }
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: none !important;
  border-bottom: none !important;
}

.instruction-tab-names {
  color: white !important;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #ffffff !important;
  border-radius: 50px;
  text-align: center;
  padding: 3px 18px !important;
  transition: none !important;
  margin-left: 10px;

}

.instruction-tab-names:focus {
  color: white !important;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #FF8F00 !important;
  background-color: #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 18px !important;
  /* transition: none !important; */
  margin-left: 10px;

}

.instruction-tab-names:hover {
  color: white !important;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #FF8F00 !important;
  background-color: #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 18px !important;
  /* transition: none !important; */
  margin-left: 10px;

}

.lyft-must-field{
  color: #FF7A00 !important;
  /* textcolor: #FF7A00 !important; */
  /* font-size: 11px; */
  /* font-weight: bolder; */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 900;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
}



.main-heading-text {
  font-size: 26px;
  color: white;
  /* margin-top: 220px; */
}

.exterior-rear-circle {
  border: 1px solid white;
  border-radius: 100%;
  padding: 8px;
  width: 0px;
  height: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 12px;
  margin-top: -205px;
}

.instruction-modal-text {
  color: white;
  margin-top: 4px;
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em !important;
  margin-left: 5px;
}

.interior-circle {
  border: 1px solid white;
  border-radius: 100%;
  padding: 8px;
  width: 0px;
  height: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 16px;
  margin-top: 4px;
}

.interior-header {
  color: white;
  margin-top: 15px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

.pic-take {
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  /* margin-top: 230px; */
}