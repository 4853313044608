.about-chex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.about-chex-left{
    width: 50%;
    padding-top: 50px;
    background-color: #8c9dae;
    padding-bottom: 50px;
}
.chex-about-left-inner{
    width: 85%;
    float: right;
}
.about-chex-left-title{
    font-family: Poppins, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 44px;
    letter-spacing: 1px;
}
.about-chex-left-title-span{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about-chex-container .about-chex-left .chex-about-left-inner > p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #6B717B;
    letter-spacing: 1px;
    margin-bottom: 5%;
}
.about-chex-inner-left-desc-span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: #FF7A00;
}
.about-chex-right{
    width: 50%;
    background-color: #FFF8F1;
    height: 400px;
    margin-top: -200px;
    padding-top: 13%;
}
.about-chex-right-inner{
    width: 80%;
    background-color: aqua;
    height: 300px;
    margin: 0 auto;
}
.about-chex-right > h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151922;
    margin-left: 20px;
}
.about-pricing-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}
.about-chex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.about-chex-left{
    width: 50%;
    padding-top: 50px;
    background-color: #8c9dae;
    padding-bottom: 50px;
}
.chex-about-left-inner{
    width: 85%;
    float: right;
}
.about-chex-left-title{
    font-family: Poppins, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 44px;
    letter-spacing: 1px;
}
.about-chex-left-title-span{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about-chex-container .about-chex-left .chex-about-left-inner > p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #6B717B;
    letter-spacing: 1px;
    margin-bottom: 5%;
}
.about-chex-inner-left-desc-span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: #FF7A00;
}
.about-chex-right{
    width: 50%;
    background-color: #FFF8F1;
    height: 400px;
    margin-top: -200px;
    padding-top: 13%;
}
.about-chex-right-inner{
    width: 80%;
    background-color: aqua;
    height: 300px;
    margin: 0 auto;
}
.about-chex-right > h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151922;
    margin-left: 20px;
}
.about-pricing-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}

.scrollContainer{
    width: 80%;
    background-color: #d7efff;
    height: 150px;
    padding-bottom: 10%;
    position: relative;
    margin-top: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.arrow-container{
    background-color: #d9e8ff;
    padding:5px;
    margin-top: 30px;
    border-radius: 50px;
}

.carousel_div_main{
    width: 75%;
}

@media screen and (max-width: 1050px){
    .carousel_div_main{
        width: 70%;
    }
}
@media screen and (max-width: 895px){
    .carousel_div_main{
        width: 65%;
    }
}
@media screen and (max-width: 768px){
    .carousel_div_main{
        width: 75%;
    }
}
@media screen and (max-width: 576px){
    .carousel_div_main{
        width: 68%;
    }
}
@media screen and (max-width: 500px){
    .heading_partners{
        margin-top: 20px;
    }
    .carousel_div_main{
        width: 75%;
        margin-top: 10px;
    }
}

@media screen and (max-width: 412px){
    .heading_partners{
        margin-top: 10px;
    }
    .carousel_div_main{
        width: 60%;
        margin-top: 10px;
    }
}
@media screen and (max-width: 330px){

    .carousel_div_main{
        width: 80%;
        margin-top: 10px;
    }
}

.footerContainer{
    background: #1B1C1F;
    padding-top: 50px;
    /*padding-left: 200px;*/
    padding-bottom: 50px;
    color:White;
}
.aboutSection{
    margin-right: 20px;
}
.footerLogoContainer{
    margin-bottom: 30px;
    width: 100%;
}
.footerAboutDescription{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #FFFFFF;
}

.headingFooter{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom:20px;
    line-height: 20px;
    color: #FFFFFF;
}
.footerMenuList{
    list-style: none;
    float: left;
    margin: 0;
    padding: 0;


}
.footerMenuList li{
    margin-bottom: 10px;
}
.footerMenuList li a{
    color: #808389!important;
    text-decoration-line: underline;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}
.footerMenuList li a:hover{
    color: #FF7A00!important;
}
.textFooter{
    font-size: 14px;

    color: #FFFFFF;
    margin-bottom: 30px;

}
.iconsContainer{
    margin-bottom: 20px;
}
.iconsContainer a{
    /*background-color: red;*/
    /* padding:20px;*/
    margin-right: 15px;
}
.footerLinks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footerLinks span{
    margin-bottom: 10px;

}
.footerLinks span Link{
    color:#FFFFFF!important;
}
.footerLinks span:hover{
    color: #FF7A00!important;
}

.copyrightText{
    text-align: center;
}
@media screen and (max-width: 900px){
    .textFooter{
        font-size:11px
    }


}

@media screen and (max-width: 768px){
    .menuContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;

    }
    .aboutSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left:30px;
        padding-right: 30px;
        text-align: center;
        margin-bottom:20px;
    }
    .footerMenuList{
        text-align: center;

    }
    .headingFooter{

        font-size: 18px;

    }
    .ContactContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
    }
    .textFooter{
        font-size: 14px;
    }
    .iconsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .copyrightText{
        text-align: center;
    }
}



/*Vehicle Inspection Section*/

.vehicleIncpectionContainer{
    padding:10px;
    padding-left: 100px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-end;*/
}
.vehicleIncpectionContainer h3{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;

}
.vehicleIncpectionContainer p{
    color:rgba(21, 25, 34, 1);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}
.vehicleListContainer_uber li{
    /*color:red;*/
    list-style: none;
    color:rgba(107, 113, 123, 1);
    font-size: 16px;
    /*float: left;*/
    margin:0px;
    padding:0px;

}
.vehicleListContainer_uber li::before {
    content: "\2022";
    color: #FF7A00;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.1rem;
}

.vehicleIncpectionContainerImg {
    display: flex;

}
.vehicleIncpectionContainerImg img{
    width: 100%;
    margin-top: -60px;

}
@media screen and (max-width: 900px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 50px;
        padding-right: 50px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-end;*/
    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }

}
@media screen and (min-width: 1800px) {
.vehicleIncpectionContainerImg img{
    width: 100%;
    margin-top: 0px;

}
}

@media screen and (max-width: 768px){
    .vehicleIncpectionContainerImg {
        display: none;

    }

    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 100px;
        padding-right: 100px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-end;*/
    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 34px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
    }
}

@media screen and (max-width: 684px) {
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}


@media screen and (max-width: 600px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 60px;
        padding-right: 60px;

    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 40px;
        padding-right: 40px;

    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }
    .vehicleListContainer li{
        /*color:red;*/
        list-style: none;
        color:rgba(107, 113, 123, 1);
        font-size: 14px;
        /*float: left;*/
        margin:0px;
        padding:0px;

    }
}


@media screen and (max-width: 360px) {
    .vehicleIncpectionContainer{
        padding:20px;


    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }
    .vehicleListContainer li{
        /*color:red;*/
        list-style: none;
        color:rgba(107, 113, 123, 1);
        font-size: 13px;
        /*float: left;*/
        margin:0px;
        padding:0px;

    }
}


/*Pricing Section Styling*/

/*.pricingSections_uber{*/
/*    background: #FFF8F1;*/
/*    padding:28px;*/
/*    padding-left: 100px;*/
/*    padding-right: 100px;*/
/*}*/
/*.headingPrcing{*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 28px;*/
/*    line-height: 31px;*/

/*    color: black;*/
/*}*/

/*.paragraphPricing{*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 14px;*/
/*    line-height: 160%;*/


/*    color: #414B55;*/

/*}*/
/*.pricingPoints{*/
/*    display: flex;*/

/*    flex-direction: row;*/
/*    padding-top: 20px;*/
/*    padding-bottom: 20px;*/

/*}*/
/*.pricingPointsText{*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 14px;*/
/*    !*line-height: 0px;*!*/
/*    color: #5D6F84;*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    .pricingPoints{*/
/*        display: flex;*/

/*        flex-direction: row;*/
/*        padding-top: 5px;*/
/*        padding-bottom: 5px;*/

/*    }*/

/*}*/


/*.pricingCardRow{*/
/*    padding-top:30px;*/
/*    padding-bottom: 30px;*/
/*}*/

/*.pricingColumn{*/
/*    width: 100%;*/


/*}*/
/*.priceCardCompo{*/
/*    margin-right: 20px;*/

/*}*/


/*@media  screen and (max-width: 1200px) {*/
/*    .pricingColumn{*/
/*        margin-top: 50px;*/
/*    }*/
/*}*/


/*@media  screen and (max-width: 768px) {*/
/*    .pricingSections_uber{*/
/*        background: #FFF8F1;*/
/*        padding:28px;*/
/*        padding-left: 60px;*/
/*        padding-right: 60px;*/
/*    }*/
/*}*/
/*@media  screen and (max-width: 590px) {*/
/*    .pricingSections_uber{*/
/*        background: #FFF8F1;*/
/*        padding:28px;*/
/*        padding-left: 30px;*/
/*        padding-right: 30px;*/
/*    }*/

/*}*/

/*.btn_div{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.btn_styling_uber{*/

/*    background-color:#FF7A00 ;*/
/*    padding:10px;*/

/*    border:1px solid #FF7A00;*/
/*    border-radius: 50px;*/
/*    padding-right: 30px;*/
/*    padding-left: 30px;*/
/*    color:white;*/
/*    margin-bottom: 10px;*/

/*}*/

/*.btn_styling_uber:hover{*/

/*    background-color:#FFFFFF ;*/


/*    border:1px solid #FF7A00;*/

/*    color:#FF7A00;*/
/*    transition: 0.5s;*/

/*}*/
.btn_styling_contact{

    background-color:#2584E0 ;
    padding:10px;

    border:1px solid #2584E0;
    border-radius: 50px;
    padding-right: 50px;
    padding-left: 50px;
    color:white;
    margin-bottom: 10px;
}
.btn_styling_contact:hover{
    background-color:#FFFFFF ;


    border:1px solid #2584E0;

    color:#2584E0;
    transition: 0.5s;
}

@media screen and (max-width: 768px){
    .btn_div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn_styling_uber{
        text-align: center;
    }
}



/*How Chex Works*/
.chexWorks-main-container-uber{
    width: 100%;
    padding-top: 20px ;
    padding-bottom: 80px;
}
.chexwork-inner-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
}
.chaxwork-heading{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
}
.chaxwork-heading-span{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF7A00;
}
.chexworks-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}
.chexwork-img{
    height: 250px;
    width: 250px;
}
.chexwork-image-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    line-height: 115.5%;
    text-align: center;
    margin-top: 10px;
    max-width: 250px;
}
.chexwork-image-desc{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-top: 10px;
    color: #6B717B;
    text-align: center;
    max-width: 250px;
}
.chexwork-image-getStarted-span{
    color: #FF7A00;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chexwork-image-payment-span{
    color: #6B717B;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}

/*Badge Banner*/

.btn_div_banner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.btn_styling_banner_uber{
    background-color:#FF7A00!important ;
    padding:10px;

    border:1px solid #FF7A00;
    border-radius: 50px;
    padding-right: 50px;
    padding-left: 50px;
    color:white!important ;
    margin-bottom: 10px;

}
.btn_styling_banner_uber:hover{
    background-color:#FFFFFF!important  ;


    border:1px solid #FF7A00!important ;

    color:#FF7A00!important ;
    transition: 0.5s;
}

.badge-container-uber{
    width: 100%;
    height: 720px;
    background-color: #2a2a2a;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom:80px;
    /* opacity: 99%; */
}
.badge-image-inner-container{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*background-color: #8c9dae;*/
}
.badge-inner-left{
    width: 50%;

    /*height: 50%;*/
}

@media screen and (max-width: 890px){
    .badge-inner-left{
        width: 100%;
        /*height: 50%;*/
    }
}

@media only screen and (max-width: 768px){
    .badge-container-uber{
        width: 100%;
        height: 460px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
    .badge-inner-left{
        margin-top: -60px;
        width: 100%;
        /*height: 50%;*/
    }
}

@media only screen and (min-width: 1140px){
    .badge-container-uber{
        width: 100%;
        height: 890px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
}

.badge-main-heading{
    font-family: Poppins;
    font-size: 34px;
    line-height: 53px;
    text-align: left;
    font-weight: bolder;
    color: #FFFFFF;
}
.badge-main-para{
    font-family: Poppins;
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    margin-bottom: 30px;

    color: #FFFFFF;
}
.contact-submit-btn {
    background: #FF7A00 !important;
    border:0px solid transparent;
    outline: none !important;
    width: 40% !important;
    height: auto;
    font-style: normal;
    font-weight:800;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    float: left;
    font-family: Poppins;

}
.contact-submit-btn:hover{
    background: #FFFFFF !important;
    border:1px solid #FF7A00 !important;
    color:#FF7A00;
    transition: 0.5s;
}

@media screen and (max-width: 575px){
    .badge-container-uber{
        padding-left: 0px;
        padding-right: 30px;
    }
    .badge-main-heading{
        text-align: center;
        font-size: 25px;
    }
    .badge-inner-left{
        display: flex;
        justify-content: center;

        align-items: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        /*height: 50%;*/
    }
    .badge-main-para{
        text-align: center;
    }
    .contact-submit-btn {
        background: #FF7A00 !important;
        border:0px solid transparent;
        outline: none !important;
        width: 60% !important;
        height: auto;
        font-style: normal;
        font-weight:800;
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        font-family: Poppins;

    }

}

@media screen and (max-width:500px ) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .badge-main-para{
        font-family: Poppins;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #FF7A00 !important;
        border:0px solid transparent;
        outline: none !important;
        width: 50% !important;
        height: auto;
        font-style: normal;
        font-weight:800;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        font-family: Poppins;

    }
}

@media screen and (max-width: 360px){
    .contact-submit-btn {
        background: #FF7A00 !important;
        border:0px solid transparent;
        outline: none !important;
        width: 70% !important;
        height: auto;
        font-style: normal;
        font-weight:800;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        font-family: Poppins;

    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .badge-main-para{
        font-family: Poppins;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 30px;

        color: #FFFFFF;
    }

}
