.landing_banner_main_wrapper_main {
  width: 100%;
  padding-top: 0px;
  background-color: #fafafa;
  /* background-size: contain; */
  background-size: 50%;
  background-position: right top;
  background-repeat: no-repeat;
  background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662109838/Vector_1_lstndg.png");
}

.form_section_main_outer {
  padding: 50px;
  background-color: #fafafa;
}
.form_section_main_inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.form_section_image_wrapper {
  width: 100%;
}
.form_section_image_wrapper img {
  width: 80%;
}
.form_section_content_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 40px;
  align-items: flex-start;
  /*background-color: rgba(255, 0, 0, 0.11);*/
}
.form_section_content_wrapper .form_heading_title {
  font-size: 28px;
  padding-bottom: 20px;
  font-weight: bold;
  font-family: Poppins;
  color: #000000;
}

.form_section_content_wrapper .form_heading_title span {
  color: #ff9900;
}

.namingSections_chex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input_field_chex_ride {
  background: rgb(255, 255, 255) !important;
  border: 1px solid #c1c1c1 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  width: 100% !important;
  margin: 0px auto 15px !important;
  padding: 15px 8px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "Poppins" !important;
  letter-spacing: 1px !important;
}

::placeholder {
  color: #585757 !important;
  padding-left: 10px;
  outline: none;
}

.terms_conditionsContainer {
  display: flex;
  flex-direction: row;
}

.termsConditionsText {
  padding-left: 10px;
  font-size: 16px;
}

.button-wrapper_chex_ride {
  background: #ff9900;
  border-radius: 5px !important;
  border: 1px solid #ff9900 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  /*line-height: 32px !important;*/
  text-align: center !important;
  color: #ffffff;

  text-decoration: none !important;
  cursor: pointer !important;
  display: block;
  width: 100% !important;
  margin: 0px auto !important;
  height: 44px !important;
}
.button-wrapper_chex_ride:hover {
  background-color: #ffffff;
  border: 1px solid #ff9900;
  color: #ff9900;
}
.button-wrapper_chex_ride:active {
  color: #ff9900 !important;
  border: 1px solid #ff9900;
}

.newcheckmark_chex_ride {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e3e2e2;
  border: 1px solid grey;
  border-radius: 3px;
}

.register-checkbox-text_chex_ride_req {
  color: rgb(57, 57, 57);
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.newcontainer:hover input ~ .newcheckmark_chex_ride {
  background-color: #ccc !important;
}
.newcontainer input:checked ~ .newcheckmark_chex_ride {
  background-color: #fff;
}
.newcheckmark_chex_ride:after {
  content: "";
  position: absolute;
  display: none;
  height: 18px !important;
  width: 18px;
  border-radius: 2px;
  border: 1px solid transparent;
  background-color: #d3d3d3;
}
.newcontainer input:checked ~ .newcheckmark_chex_ride:after {
  content: "\2713";
  display: block;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  /*margin-top: -10px;*/

  text-align: center;

  color: #ff7a00;
}

@media screen and (max-width: 1799px) {
  .form-item-style-chex_first_ride {
    width: 48%;
  }
}

@media screen and (max-width: 842px) {
  .form-item-style-chex_first_ride {
    width: 100%;
  }

  .namingSections_chex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

/* @media screen and (max-width: 510px) {
  .register-checkbox-text_chex_ride_req {
    font-size: 12px;
  }
} */

@media screen and (min-width: 1800px) {
  .namingSections_chex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.login-landing-signup_ride {
  display: "flex";
  margin-top: 15px;
}

@media screen and (min-width: 1440px) {
  .login-landing-signup_ride {
    display: "flex";
    margin-top: 3px;
  }
}

@media screen and (max-width: 1020px) {
  .form_section_image_wrapper {
    width: 100%;
  }
  .form_section_image_wrapper img {
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .form_section_main_outer {
    /*background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662631072/Group_423_v53nfc.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
    padding: 0px;
  }
  .form_section_main_inner {
    background-color: rgba(236, 236, 236, 0.7);
    padding: 30px;
  }
  .form_section_image_wrapper {
    width: 100%;
    display: none;
  }
  .form_section_content_wrapper {
  }

  .form_section_content_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 40px;
    align-items: flex-start;
    /*background-color: rgba(255, 0, 0, 0.11);*/
  }
  .form_section_content_wrapper .form_heading_title {
    font-size: 28px;
    padding-bottom: 20px;
    font-weight: bold;
    font-family: Poppins;
    color: #000000;
  }
}

@media screen and (max-width: 600px) {
  .form_section_content_wrapper {
    padding-left: 0px;
  }
}

@media screen and (max-width: 380px) {
  .form_section_main_inner {
    padding: 10px;
  }

  .form_section_content_wrapper .form_heading_title {
    font-size: 20px;
  }
}

/*---------------------------------------------------------------------------------------------*/
.landing_banner_inner_wrapper2 {
}
.main-container-box {
  padding-top: 150px;
  padding-left: 80px;
  padding-right: 50px;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 100px;
  margin: 0 auto;
}

.container-left {
  /* padding: 20px; */
}
.container-right {
  padding: 20px;
}

@media (max-width: 800px) {
  .grid-wrapper {
    grid-template-columns: 1fr;
  }
  .main-container-box {
    padding-top: 120px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .landing_banner_main_wrapper_main {
    background-image: none;
  }
}

/* @media screen and (max-width: 600px) {
  
  
} */

.heading-text {
  color: #1b1c1f;
  font-size: 35px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 48.38px;
  word-wrap: break-word;
}
.container-text {
  color: #1b1c1f;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 36.55px;
  word-wrap: break-word;
}
.container-text-2 {
  color: #1b1c1f;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 40.68px;
  word-wrap: break-word;
  text-align: center;
  margin-top: 50px;
  width: 100%;
  margin-bottom: -150px;
}
.chex-demo-video-main-contaiera {
  margin-top: 10%;
  display: flex;
  justify-content: center;
  /* border-radius: 3%; */
}
.chex-demo-video-containera {
}

.chex-demo-videoa {
  border-radius: 10px;
  /* height: 350px; */
  width: 100%;
  border: none;
  z-index: 1;
}
.right-container-box {
  border: 1px solid rgb(136, 136, 136);
  border-radius: 10px;
  height: fit-content;
  margin-bottom: -30px;
}

.rigth-container-wrapper {
  padding: 40px;
}
.right-container-text {
  margin-bottom: 20px;
}
/* @media screen and (min-width: 880px) {
    .right-container-box{
       height: 80%;
        
    }
  } */
.techStar-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10%;
  margin-bottom: -35px;
  padding-bottom: 50px;
}
.img_techStar2 {
  width: 30%;
}

@media screen and (max-width: 835px) {
  .container-text-2 {
    margin-bottom: -50px;
  }
}

@media screen and (max-width: 835px) {
  .techStar-box {
    padding-top: 12%;
  }
}
