.landing_banner_main_wrapper {
  width: 100%;
  background-color: #fafafa;
}

.landing_main_section_container {
  margin-bottom: 0px;
  margin-top: 0px;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/dgjordf6e/image/upload/v1726233443/main-section-background_q4gnqh.png");
  position: relative;
  min-height: 100vh; /* Ensure full viewport height initially */
}

.landing_main_section_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Change to 100% to match the container's height */
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.landing_main_section_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.landing_main_section_inner {
  width: 100%;
  max-width: 1300px;
  margin-top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  z-index: 2;
  margin-bottom: 80px;
}

.landing_main_section_text {
  flex: 1 1 35%;
  padding-right: 20px;
  box-sizing: border-box;
}

.landing_main_section_image {
  flex: 1 1 65%;
  text-align: end;
  box-sizing: border-box;
}

.landing_image {
  width: 100%;
  max-width: 550px;
  height: auto;
}

.landing_main_section-heading {
  text-transform: capitalize;
  color: white;
  font-size: 55px;
  max-width: 500px;
}

.highlight {
  background-color: #1468ba;
  padding: 3px;
}

.landing_main_section_description {
  color: white;
  text-transform: capitalize;
}

.btn_landing_wrapper {
  margin-top: 50px;
}

.btn_landing {
  padding: 10px 30px;
  background-color: #438be3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Media queries */
@media screen and (max-width: 1230px) {
  .landing_main_section-heading {
    font-size: 50px;
  }
}

@media screen and (max-width: 1150px) {
  .landing_main_section-heading {
    font-size: 45px;
  }
}

@media screen and (max-width: 950px) {
  .landing_main_section-heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 800px) {
  .landing_main_section-heading {
    font-size: 35px;
  }
}

@media screen and (max-width: 680px) {
  .landing_main_section_inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing_main_section_text {
    text-align: center;
    padding-right: 0;
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing_main_section_image {
    display: none;
  }

  .btn_landing_wrapper {
    margin-top: 20px;
  }
}
/* 
@media screen and (max-width: 560px) {
  .landing_main_section-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 420px) {
  .landing_main_section_inner {
    margin-bottom: 82px;
  }
} */
