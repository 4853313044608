.community_wrapper_main_outer{
    background-color: #1468BA;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;

    padding-bottom: 60px;
}

.community_wrapper_main_inner{
    width: 100%;
}
.community_wrapper_main_inner .community_wrapper_heading{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: Poppins;
    color: #ffffff;
    margin-bottom: 50px;

}

.community_wrapper_main_inner .community_wrapper_heading span{
    color: #FF9900;

}

.community_wrapper_counter{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*background-color: red;*/

}

.counter_box{
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;



}
.counter_box h2{
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    font-family: Poppins;
    color: #ffffff;
}

.counter_box h3{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    font-family: Poppins;
    height: 40px;
    color: #ffffff;
}

.center_counter_box_line{
    padding-left: 50px;
    padding-right: 50px;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    margin-left: 30px;
    margin-right: 30px;
}


@media screen and (max-width: 820px) {
    .community_wrapper_main_inner .community_wrapper_heading{
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
        margin-bottom: 50px;

    }
    .counter_box h2{
        font-size: 35px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }

    .counter_box h3{
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }
    .center_counter_box_line{
        padding-left: 30px;
        padding-right: 30px;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        margin-left: 20px;
        margin-right: 20px;
    }


}




@media screen and (max-width:640px) {
    .community_wrapper_main_inner .community_wrapper_heading{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
        margin-bottom: 40px;

    }
    .counter_box h2{
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }

    .counter_box h3{
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }
    .center_counter_box_line{
        padding-left: 20px;
        padding-right: 20px;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        margin-left: 10px;
        margin-right: 10px;
    }


}






@media screen and (max-width:439px) {

    .community_wrapper_counter{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        /*background-color: red;*/

    }

    .community_wrapper_main_inner .community_wrapper_heading{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
        margin-bottom: 40px;

    }
    .counter_box h2{
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }

    .counter_box h3{
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }
    .center_counter_box_line{
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        border-left: none;
        border-right: none;
        margin-left: 0px;
        margin-right: 0px;

        margin-top: 10px;
        margin-bottom: 10px;
    }


}