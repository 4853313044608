.veh-inspection-chexai_text-logo{
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
}


.inspection-image-card{
    width: 100%;
    height: 140px;
    border-radius: 10px;
    object-fit: initial;
}

.main_button_scooter_wrapper{
    padding-left: 30px;
    padding-right: 30px;
}
.inspection-button-style-scooter {
    background-color: #ff7a00;
    width:100%;
    padding: 7px;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 100px;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    margin-top: 15px;
    cursor: pointer;
    height: 54px !important;
}

.heading_scooter_image h4{
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
    font-family: Poppins;
    text-align: center;
}

.loader_wrapper_center{
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;

}