.section_pricing_micro_mobility{
    padding: 50px;
    background-color: #FAFAFA;

}
.inner_section_pricing_mobility{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 400px;

}

.inner_section_pricing_mobility h3{
    font-size: 30px;
    color: #000000;
    font-family: Poppins;
    font-weight: bold;

}
.inner_section_pricing_mobility p{
    font-size: 20px;
    color: #000000;
    font-family: Poppins;
    font-weight: 400;
    text-align: center;

}
.inner_section_pricing_mobility .btn_pricing_mobility{
    background-color: #1468BA;
    color: #ffffff;
    border: 1px solid #1468BA;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.inner_section_pricing_mobility .btn_pricing_mobility:hover{
    background-color: #ffffff;
    color: #1468BA;
    border: 1px solid #1468BA;
    transition: 0.6s;
}


@media screen and (max-width: 682px ) {
    .inner_section_pricing_mobility h3{
        font-size: 24px;
        color: #000000;
        font-family: Poppins;
        font-weight: bold;

    }
    .inner_section_pricing_mobility p{
        font-size: 16px;
        color: #000000;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;

    }

}

@media screen and (max-width: 480px ) {
    .inner_section_pricing_mobility{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 300px;

    }
    .inner_section_pricing_mobility h3{
        font-size: 22px;
        color: #000000;
        font-family: Poppins;
        font-weight: bold;

    }
    .inner_section_pricing_mobility p{
        font-size: 14px;
        color: #000000;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;

    }
    .inner_section_pricing_mobility .btn_pricing_mobility{
        background-color: #1468BA;
        color: #ffffff;
        border: 1px solid #1468BA;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }
}



@media screen and (max-width: 345px ) {
    .section_pricing_micro_mobility{
        padding: 20px;
        background-color: #FAFAFA;

    }
    .inner_section_pricing_mobility{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 300px;

    }
    .inner_section_pricing_mobility h3{
        font-size: 18px;
        color: #000000;
        font-family: Poppins;
        font-weight: bold;

    }
    .inner_section_pricing_mobility p{
        font-size: 12px;
        color: #000000;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;

    }
    .inner_section_pricing_mobility .btn_pricing_mobility{
        background-color: #1468BA;
        color: #ffffff;
        border: 1px solid #1468BA;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }
}