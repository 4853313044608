.modal-styling {
    left: 0 !important;
    padding: 0 !important;
    right: 0 !important;
    width: 100%; 
    height: 100%;
    overflow: scroll;
}
.checkmark::before {
    display: none !important;
}  
.checkmark::after {
    left: 4px !important;
    top: -7px !important;
    width: 11px !important;
    height: 25px !important;
    border: 1px solid #FC6522 !important;
    border-width: 0 3px 3px 0 !important;
} 
.left-icon-termsAndPolicy {
    font-size: 20px !important;
}