.main_section_review_custom {
  position: relative;
  margin-top: 40px;
}
.review_container_main_new {
  max-width: 1300px;
  margin: 0 auto;
}

.card-component {
  background-color: #f9f9f9;
  max-height: 676px;
  /* padding-bottom: 10px; */
  padding-top: 30px;
}

.review_container_main_new .rec.rec-arrow-right {
  bottom: -60%;
  right: 6%;
}
.review_container_main_new .rec.rec-arrow-left {
  left: 6%;
  bottom: -60%;
}
/* .rec-carousel {
  padding-top: 20px;
} */

.review-card-items {
  margin: 10px;
}

@media only screen and (max-width: 1200px) {
  /* .review_container_main_new .rec.rec-arrow-right {
    top: 140% !important;
    right: 6%;
  }
  .review_container_main_new .rec.rec-arrow-left {
    top: 140% !important;
    left: 6%;
  } */
}

.review-card-items {
  margin: 10px;
}

@media only screen and (max-width: 1014px) {
  /* .review_container_main_new .rec.rec-arrow-right {
    top: inherit;
    bottom: -20%;
    right: 43%;
  }
  .review_container_main_new .rec.rec-arrow-left {
    top: inherit;
    bottom: -20%;

    left: 43%;
  } */
  .review-card-items {
    margin: 20px;
  }
}

@media screen and (max-width: 767px) {
  .main_section_review_custom {
    /*margin-bottom: 0px;*/
  }
}

.headingCustomerReviews_custom {
  padding-left: 0px;
  font-size: 35px;
  font-family: Poppins;
  color: black;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.main_section_review_custom .para_heading_sub {
  font-size: 18px;
  font-family: Poppins;
  color: black;
  text-align: center;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .headingCustomerReviews_custom {
    font-size: 30px;
  }
}
@media screen and (max-width: 570px) {
  .headingCustomerReviews_custom {
    font-size: 26px;
  }
}
@media screen and (max-width: 455px) {
  .main_section_review_custom .para_heading_sub {
    font-size: 14px;
  }
}
@media screen and (max-width: 320px) {
  .main_section_review_custom .para_heading_sub {
    font-size: 12px;
  }
}

.review_container_main_new {
  width: 100%;

  background-color: #ffffff;
}

@media screen and (max-width: 767px) {
  .review_container_main_new {
    width: 100%;
    background-color: #ffffff;

    padding-top: 0px;
    margin-bottom: 30px;
  }

  /* .review_container_main_new .rec.rec-arrow-right {
    background-color: #f4f9ff;
    border-radius: 30px;
    position: absolute;
    right: 40%;
    bottom: -10%;
  } */
  .review_container_main_new .rec.rec-arrow:hover {
    background-color: #f4f9ff !important;
    color: black !important;
  }
  .review_container_main_new .rec.rec-arrow:focus {
    background-color: #f4f9ff !important;
    color: black !important;
  }

  .review_container_main_new .rec.rec-arrow:hover {
    background-color: #f4f9ff;
  }
  /* .review_container_main_new .rec.rec-arrow-left {
    background-color: #f4f9ff;
    border-radius: 30px;
    z-index: 1;
    bottom: -10%;
    position: absolute;
    left: 40%;
  } */
}

@media only screen and (max-width: 480px) {
  .review_container_main_new {
    width: 100%;

    background-color: #ffffff;
    /* padding-top: 30px; */
  }

  /* .review_container_main_new .rec.rec-arrow-right {
    right: 35%;
    bottom: -10%;
  }
  .review_container_main_new .rec.rec-arrow-left {
    left: 35%;
    bottom: -10%;
  } */
}

@media screen and (max-width: 360px) {
  /* .review_container_main_new .rec.rec-arrow-right {
    right: 30%;
    bottom: -10%;
  }
  .review_container_main_new .rec.rec-arrow-left {
    left: 30%;
    bottom: -10%;
  } */
}

@media screen and (max-width: 330px) {
  /* .review_container_main_new .rec.rec-arrow-right {
    right: 30%;
    bottom: -10%;
  }
  .review_container_main_new .rec.rec-arrow-left {
    left: 30%;
    bottom: -10%;
  } */
}
