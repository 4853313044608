.faqsContainerStyle{

    padding-left: 100px;
    padding-right:100px;
}

.faqsDivWrapper{
    background-color: #fff;
    margin-bottom: 10px;
    border: 1px solid black;
}


@media screen and (max-width: 767px){
    .faqsContainerStyle{

        padding-left: 60px;
        padding-right:60px;
    }

}

@media screen and (max-width: 460px){
    .faqsContainerStyle{

        padding-left: 30px;
        padding-right:30px;
    }

    .contactText_faqs{
        font-size: 18px!important;
        font-weight: bold;
    }
}