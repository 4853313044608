.custom-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background: #f9fafb;
  border-radius: 8px;
  width: 400px;
  height: 200px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.custom-modal-close {
  position: absolute;
  top: -4px;
  right: 3px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.custom-modal-content {
  text-align: center;
}

.custom-modal-content h2 {
  color: #1f2937;
  margin-bottom: 15px;
  padding-top: 20px;
}

.custom-modal-content p {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.5;
}

.custom-modal-content a {
  color: #2563eb;
  font-weight: bold;
  text-decoration: none;
}

.custom-modal-content a:hover {
  text-decoration: underline;
}

/* Media queries */
@media screen and (max-width: 767px) {
  .custom-modal {
    width: 90%;
    padding: 15px;
    height: 170px;
  }

  .custom-modal-content h2 {
    font-size: 28px;
    padding-top: 20px;
  }

  .custom-modal-content p {
    font-size: 18px;
  }
}

@media screen and (max-width: 720px) {
  .custom-modal {
    width: 85%;
    padding: 15px;
    height: 130px;
  }

  .custom-modal-content h2 {
    font-size: 20px;
    padding-top: 10px;
  }
  .custom-modal-close {
    top: -4px;
    right: 3px;
  }
  .custom-modal-content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .custom-modal {
    width: 80%;
    padding: 15px;
  }

  .custom-modal-content h2 {
    font-size: 18px;
  }
  .custom-modal-close {
    top: -4px;
    right: 3px;
  }
  .custom-modal-content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .custom-modal {
    width: 75%;
    padding: 10px;
    height: 130px;
  }

  .custom-modal-content h2 {
    font-size: 18px;
    padding-top: 10px;
  }
  .custom-modal-close {
    top: -4px;
    right: 3px;
  }

  .custom-modal-content p {
    font-size: 13px;
  }
}

@media screen and (max-width: 500px) {
  .custom-modal {
    width: 70%;
    padding: 10px;
    height: 150px;
  }

  .custom-modal-content h2 {
    font-size: 16px;
    padding-top: 10px;
  }
  .custom-modal-close {
    top: -4px;
    right: 3px;
  }

  .custom-modal-content p {
    font-size: 12px;
  }
}
