.contact-us-logo {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #0071B2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-us-logo img {
    width: 30px;
    height: 30px;
  }
  .main-contact-logo-div {
    
    cursor: pointer;
  }