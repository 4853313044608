.submitted-tile{
    background: #fff !important;
    cursor: pointer;
    width: 90%;
    border-bottom: none !important;
    /* height: 127px; */

    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px !important;
    margin-top: 30px !important;
    position: relative;

}
.submitted-tracking-id-text{
    margin-left: 15px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
}
.submitted-tracking-id{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 25px;
    color: #000000;
    margin-bottom: 2px;
}
.submitted-tile-content{
    display: flex;
    position: absolute;
}
.submitted-date-created{
    margin-left: 35px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
}
.submitted-date{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 48px;
    color: #000000;
}

.car-icon-style {
    width: 20px;
}