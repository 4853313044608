.certificates-tile {
  cursor: pointer;
  width: 90%;
  /* height: 127px; */
  padding: 10px;
  margin-top: 27px;
  /* margin-right:7px; */
  box-shadow: 0 0 8px silver;
  border-radius: 4px;
  /* margin-left: 20px; */
  position: relative;
  overflow: hidden !important;
}

@media only screen and (max-width: 352px) {
  .certificates-tile {
    padding: 5px;

  }
}

.certificates-tracking-id-text {
  margin-left: 10px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468BA;
}

@media only screen and (max-width: 352px) {
  .certificates-tracking-id-text {
    font-size: 12px;

  }
}


.certificates-tracking-id {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 8px;
  color: #000000;
  text-align: center;
}

@media only screen and (max-width: 352px) {
  .certificates-tracking-id {
    font-size: 12px;

  }
}

.certificates-tile-content {
  display: flex;
  justify-content: space-around;
}

.certificates-date-created {
  margin-left: 17px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468BA;
}

@media only screen and (max-width: 352px) {
  .certificates-date-created {
    font-size: 12px;

  }
}

.certificates-date {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 8px;
  margin-left: 20px;
  color: #000000;
  text-align: center;

}

@media only screen and (max-width: 352px) {
  .certificates-date {
    font-size: 12px;

  }
}

.certificates-button {

  margin-top: 10px;
  /* padding: 0px 30px; */
  /* width: 193px; */
  height: 39px;
  background: #1468BA;
  border-radius: 5px;
  border: 1px solid #1468BA;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

@media only screen and (max-width: 352px) {
  .certificates-button {
    font-size: 10px;
    /* width:150px; */
    height: 25px;

  }
}

.certificates-company {
  margin-left: 17px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1468BA;
}

@media only screen and (max-width: 352px) {
  .certificates-company {
    font-size: 12px;

  }
}

.certificates-company-name {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 15px;
  margin-left: 10px;
  color: #000000;
  text-align: center;

}

@media only screen and (max-width: 352px) {
  .certificates-company-name {
    font-size: 12px;

  }
}

.ant-modal-close {
  display: none;
}