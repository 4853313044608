.header-stylingd{
    box-shadow:2px 2px 30px #e9e7e7;
    height:100px;
}
.Blog-images {
  position:relative;
  width:100%;

}
.blog-detail-images{
  width: 100%;
   height: 570px;
   object-fit: contain;
}
.DateContentS{
  font-size:13px;
  margin-top:4%;
  font-weight: 700;
  color: #fff;
}
.ContentStylingS{ 
  margin-top: 5% ;
  /* text-align:justify; */
  margin-left:5%;
  margin-right:5%
}
.Blog-title-styling{
  font-weight:700;
  font-size:39px;
  color: #fff; 
  /* margin-right: 4%;  */
  width:90%
}
.publishbytextstyle{
  float:right;
  margin-right:5%;
  font-size:13px
}
@media only screen and (max-width:1370px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.3% */
  }
  .Blog-title-styling{
    font-weight:700;
    font-size:36px;
    color: #fff;  
  }
  .blog-detail-images{
    width:100%;
    height:520px;
  }
 
}
@media only screen and (max-width:1237px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.3% */
  }
  .Blog-title-styling{
    font-weight:700;
    font-size:36px;
    color: #fff;  
  }
  .blog-detail-images{
    width:100%;
    height:510px;
  }
 
}
@media only screen and (max-width:1127px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.5% */
  }
  .Blog-title-styling{
    font-weight:700;
    font-size:34px;
    color: #fff;  
  }
  .blog-detail-images{
    width:100%;
    height:480px;
  }
}
@media only screen and (max-width:1040px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.9% */
  }
}
@media only screen and (max-width:1001px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-5.1% */
  }
}
@media only screen and (max-width:950px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-5.4% */
  }
}
@media only screen and (max-width:880px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-5.7% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:31px;
    color: #fff;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:470px;
  }

}
@media only screen and (max-width:830px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-6% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:28px;
    color: #fff;
    /* color: rgb(26, 25, 25); */
  }
}
@media only screen and (max-width:769px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-6.6% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:25px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:460px;
  }
}
@media only screen and (max-width:720px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-7%
     */
  }
  .ContentStylingS{ 
    margin-top: 5% ;
    margin-left:5%;
    margin-right:5%;
    /* margin-top:2%; */
  }
}
@media only screen and (max-width:670px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-7.5% */
  }
  .blog-detail-images{
    width:100%;
    height:430px;
  }
}
@media only screen and (max-width:620px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-8.2% */
  }
}
@media only screen and (max-width:588px) {
  
  .Blog-title-styling{
    font-weight:700;
    font-size:23px;
  }
}
@media only screen and (max-width:576px) {
  
  .DateContentS{
    font-size:11px;
    margin-top:2%;
  }
  .ContentStylingS{ 
    margin-top: 5% ;
    margin-left:5%;
    margin-right:5%;
    /* margin-top:2%; */
  }
  .publishbytextstyle{
    float:right;
    margin-right:5%;
    font-size:9px
  }
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-8.9% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:21.5px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:400px;
  }
}

@media only screen and (max-width:540px) {
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:20px;
    /* color: rgb(26, 25, 25); */
  }
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-9.3% */
  }
  .blog-detail-images{
    width:100%;
    height:380px;
  }
}
@media only screen and (max-width:497px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-10% */
  }
}
@media only screen and (max-width:479px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-10.4% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:18.5px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:360px;
  }
}
@media only screen and (max-width:458px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-11% */
  }
}
@media only screen and (max-width:439px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-11.5% */
  }
}
@media only screen and (max-width:420px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-12% */
  }
  .DateContentS{
    /* color: black; */
    
    font-size:10px;
    margin-top:2%;
  }

  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:16px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:340px;
  }
}
@media only screen and (max-width:400px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-12.5% */
  }
  .blog-detail-images{
    width:100%;
    height:325px;
  }
  .ContentStylingS{ 
    margin-top: 5% ;
    margin-left:5%;
    margin-right:5%;
    /* margin-top:2%; */
  }
}
@media only screen and (max-width:380px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-13.2% */
  }
}
@media only screen and (max-width:362px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-14% */
  }
  .DateContentS{
    /* color: black; */
    font-size:8px;
    margin-top:2%;
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:15px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:317px;
  }

}
@media only screen and (max-width:330px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-15% */
  }
  .blog-detail-images{
    width:100%;
    height:296px;
  }

}
@media only screen and (max-width:320px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-16% */
  }
}






