.video-confirmation-modal-text{
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    z-index: 1000;
    color: black;
}
.video-confirmation-inspection-text{
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
        margin-top: 10px;
}
.video-confirmation-modal-btn{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.video-confirmation-modal-btn-no{
    width: 135px;
    border-radius: 100px;
    border: 1px solid #1468BA;
    text-align: center;
    padding: 9px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-top: 30px;
}
.video-confirmation-modal-btn-yes{
    color: white !important;
    width: 135px;
    border-radius: 100px;
    background: linear-gradient(90deg, #FF7A00 0%, #FF9900 94.74%);
    text-align: center;
    padding: 9px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.video-confirmation-modal .ant-modal-content{
    height: 175px !important;
    cursor: pointer;
    width: 340px !important;
} 
