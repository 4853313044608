/* .transcation-container {
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; }*/
.trans-container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b;
  /*height: 100vh !important;*/
  /*position: absolute;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
}
.transcation-container{
  position: relative;
}
.arrows_top_header{

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center!important;
  padding-bottom: 10px;

}
.arrows_style{

  position: absolute;
  left:0%;
  /*justify-content: flex-start!important;*/
  /*align-items: flex-start;*/
}
.chex_wrapper_main_text{
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;

  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.transaction-screen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
  padding: 10px;
}
.header-icon {
  color: #2584e0;
  width: 20px;
  height: 32px;
}
.header-text {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.transaction-payment {
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-font: Poppins;
  color: #FF7A00;
  margin-bottom: -8px;
}
.transaction-amount {
  font-size: 33px!important;
  font-font: Poppins;
  font-weight: 600;
}

.transaction-payment-heading{
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  color: black;
}

.amount-detail-style{
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(31, 59, 85, 0.67) !important;
  font-family: Inter, Arial;
  font-size: 14px;
}

.transaction-form-container {
  background-color: #fff;
  border-radius: 20px;
  border-bottom-left-radius:0px!important;
  border-bottom-right-radius:0px!important;
  width: 375px;
  padding: 20px;
  margin-bottom: 0px;
}
.input-field-name {
  font-size: 16px;
  font-weight: 600!important;
  color: #3F5162!important;
  margin-bottom: 5px!important;
  margin-top: 10px;
  font-family: 'Poppins' !important;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 1000px;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: none;
  border: 1px solid #DDE3E8;
  border: 0;
  outline: 0;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
}



.inputtransaction.StripeElement {
  display: block!important;
  margin: 10px 0 20px 0!important;
  max-width: 1000px!important;
  padding: 10px 14px!important;
  font-size: 1em!important;
  font-family: 'Source Code Pro', monospace;
  box-shadow: none!important;
  border: 1px solid #DDE3E8!important;
  outline: 0!important;
  border-radius: 12px!important;
  background-color: #fff!important;
  width: 100%!important;
}

@media only screen and (max-width: 600px) {

  .transaction-form-container {
    background-color: #fff;
    border-radius: 20px;
    width:365px;
    /*width:100%;*/
    padding: 20px;
    margin-bottom: 0px;
  }

}


@media only screen and (max-width: 500px) {
  .transcation-container{
    width: 100%;
  }
  .transaction-form-container {
    background-color: #fff;
    border-radius: 20px;
    border-bottom-left-radius:0px!important;
    border-bottom-right-radius:0px!important;
    width:100%;
    padding: 20px;
    margin-bottom: 0px;
  }

}




.cardholder-input_main{
  font-size: 18px;
  font-family: Poppins;
  box-shadow: none!important;
  background-color: #fff!important;
  color: #1F3B55!important;
  border: 1px solid #DDE3E8!important;
  font-weight: 500;
  border-radius: 12px;
  padding: 10px 14px!important;

}

.cardholder-input_main::placeholder{
  font-size: 18px!important;
  background-color: #fff!important;
  color: #486288!important;

}
@media only screen and (min-width: 1360px) {
  .cardholder-input {
    width: 100%;
    /* height: 100% !important; */
  }
}
