.how-it-work-container {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.how-it-work-inner {
  max-width: 1300px;
  margin: auto;
  width: 100%;
}

.how-it-work-divs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.how-it-work-column1 {
  flex: 1 1 33%;
}

.how-it-work-column2 {
  flex: 1 1 66%;
}

.how-it-work-column2-divs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.how-it-work-column1-head {
  font-size: 35px;
  color: #1468ba;
}

.how-it-work-column1-para {
  font-size: 17px;
  text-transform: capitalize;
  padding-top: 20px;
}

.how-it-work-column1-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 40px;
  border-radius: 6px;
  background: #438be3;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.how-it-work-column2-card-1 {
  background-color: #f68b1f;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid rgb(199, 199, 199);
  margin: 10px;
  width: 360px;
}
.how-it-work-column2-card-1-index {
  background-color: #ffffff;
  color: #f68b1f;
  height: 40px;
  width: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.how-it-work-column2-card-1-head {
  color: #ffffff;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.how-it-work-column2-card-1-para {
  color: #ffffff;
  text-transform: capitalize;
}

.how-it-work-column2-card-2 {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid rgb(199, 199, 199);
  margin: 10px;
  width: 360px;
  text-transform: capitalize;
}
.how-it-work-column2-card-2-index {
  background-color: #fffaf5;
  color: #f68b1f;
  height: 40px;
  width: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid;
}
.how-it-work-column2-card-2-head {
  color: #000000;
  margin-top: 20px;
  font-weight: 600;
}

/* Default styles for card 2 */
.how-it-work-column2-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid rgb(199, 199, 199);
  margin: 10px;
  width: 360px;
  text-transform: capitalize;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.how-it-work-column2-card-index {
  background-color: #fffaf5;
  color: #f68b1f;
  height: 40px;
  width: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid;
}

.how-it-work-column2-card-head {
  color: #000000;
  margin-top: 20px;
  font-weight: 600;
}

.how-it-work-column2-card-para {
  color: #000000;
  text-transform: capitalize;
}

/* Hover styles to apply card 1 styling */
.how-it-work-column2-card:hover {
  background-color: #f68b1f;
  border: 1px solid rgb(199, 199, 199);
}

.how-it-work-column2-card:hover .how-it-work-column2-card-index {
  background-color: #ffffff;
  color: #f68b1f;
}

.how-it-work-column2-card:hover .how-it-work-column2-card-head,
.how-it-work-column2-card:hover .how-it-work-column2-card-para {
  color: #ffffff;
}

@media screen and (max-width: 1030px) {
  .how-it-work-column1,
  .how-it-work-column2 {
    flex: 1 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .how-it-work-column2-card-1,
  .how-it-work-column2-card-2 {
    width: 100%;
    margin: 10px 0;
  }

  .how-it-work-column1-para {
    font-size: 15px;
    text-transform: capitalize;
    padding-top: 20px;
  }

  .how-it-work-column1-head {
    font-size: 33px;
    color: #1468ba;
  }
  .how-it-work-column1-btn {
    width: 130px;
    height: 45px;
    font-weight: 500;
  }
  .how-it-work-container {
    padding-top: 30px;
  }
}

@media screen and (max-width: 810px) {
  .how-it-work-column2-divs {
    flex-direction: column;
    align-items: center;
  }

  .how-it-work-column2-card-1,
  .how-it-work-column2-card-2 {
    max-width: 360px;
  }

  .how-it-work-column1-para {
    font-size: 13px;
    text-transform: capitalize;
    padding-top: 20px;
  }

  .how-it-work-column1-head {
    font-size: 31px;
    color: #1468ba;
  }

  .how-it-work-column1-btn {
    width: 125px;
    height: 40px;
    font-weight: 500;
  }
  .how-it-work-container {
    padding-top: 20px;
  }
}

@media screen and (max-width: 769px) {
  .how-it-work-column1,
  .how-it-work-column2 {
    flex: 1 1 100%;
    margin-bottom: 10px;
    align-items: center;
  }
  .how-it-work-column1-para {
    font-size: 14px;
    text-transform: capitalize;
    padding-top: 20px;
  }

  .how-it-work-column1-head {
    font-size: 26px;
    color: #1468ba;
  }
  .how-it-work-column1-btn {
    width: 120px;
    height: 35px;
    font-weight: 500;
  }

  .how-it-work-container {
    padding-top: 10px;
  }
}

/* 
.how-it-work-container {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.how-it-work-inner {
  max-width: 1300px;
  margin: auto;
}
.how-it-work-divs {
  display: flex;
  flex-wrap: wrap;
}

.how-it-work-column1 {
  flex: 1 1 33%;
  padding-left: 20px;
}

.how-it-work-column2 {
  flex: 1 1 66%;
}
.how-it-work-column2-divs {
  display: flex;
}
.how-it-work-column1 {
}
.how-it-work-column1-head {
  font-size: 40px;
  color: #1468ba;
}
.how-it-work-column1-para {
  font-size: 17px;
  text-transform: capitalize;
  padding-top: 20px;
}
.how-it-work-column1-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 40px;
  border-radius: 6px;
  background: #438be3;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.how-it-work-column2 {
}

.how-it-work-column2-card-1 {
  background-color: #f68b1f;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid rgb(199, 199, 199);
  margin: 10px;
  width: 360px;
}
.how-it-work-column2-card-1-index {
  background-color: #ffffff;
  color: #f68b1f;
  height: 40px;
  width: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.how-it-work-column2-card-1-head {
  color: #ffffff;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.how-it-work-column2-card-1-para {
  color: #ffffff;
  text-transform: capitalize;
}

.how-it-work-column2-card-2 {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid rgb(199, 199, 199);
  margin: 10px;
  width: 360px;
  text-transform: capitalize;
}
.how-it-work-column2-card-2-index {
  background-color: #fffaf5;
  color: #f68b1f;
  height: 40px;
  width: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid;
}
.how-it-work-column2-card-2-head {
  color: #000000;
  margin-top: 20px;
  font-weight: 600;
}

@media screen and (max-width: 1350px) {
}

@media screen and (max-width: 1030px) {
  .how-it-work-column1,
  .how-it-work-column2 {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 810px) {
  .how-it-work-column2-divs {
    display: flex;
    flex-direction: column;
  }
  .how-it-work-column2-card-1,
  .how-it-work-column2-card-2,
  .how-it-work-column2-card-2 {
    max-width: 360px;
  }
}

@media screen and (max-width: 769px) {
  .how-it-work-column1,
  .how-it-work-column2 {
    flex: 1 1 100%;
    margin-bottom: 10px;
  }
} */
