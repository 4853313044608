@import url("https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap");
.priceCardContainer {
  background-color: white;
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
  border-radius: 10px;
  /*width: 300px;*/
  position: relative;
  /*height: 200px;*/
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;

  justify-content: center;

  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;

  /*text-align: center;*/

  /*margin:100px;*/
}
.pricetagBg_new {
  position: absolute;
  top: -8%;
  left: 5%;
  background-color: #ff7a00;
  border-radius: 5px;

  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  height: 30px;
}

.pricecardHeading {
  color: #ff7a00;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 28px;
}
.pricecardHeading {
  font-size: 40px;
  line-height: 28px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.discountPricecardHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 30px;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;

  color: #5d6f84;
}

.pricetagText_new {
  padding-top: 3px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 3px;
  width: 100%;
  height: 100%;
  text-transform: uppercase;

  color: #ffffff;
}

.lyftDiscount {
  del {
    color: #2584e0;
    text-decoration: none;
    position: relative;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 28px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 5px;

    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 3px solid rgba(255, 0, 0, 0.8); /* Corrected to use proper rgba format */
      height: 12px;
      position: absolute;
      bottom: 30%; /* Adjusted for proper positioning */
      left: 0;
      transform: rotate(10deg); /* Adjust the angle as needed */
    }
  }
}

@media screen and (max-width: 1100px) {
  .pricecardHeading {
    font-size: 55px;
    line-height: 25px;
    margin-top: 20px;
  }
  .pricecardParagraph {
    font-size: 16px;

    color: #5d6f84;
  }
}

@media screen and (max-width: 935px) {
  .pricetagBg_new {
    position: absolute;
    top: -8%;
    left: 8%;
    background-color: #ff7a00;
    border-radius: 5px;

    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
  }
  .pricetagText_new {
    padding-top: 3px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 3px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;

    color: #ffffff;
  }

  .pricecardHeading {
    font-size: 45px;
    line-height: 25px;
    margin-top: 20px;
  }
  .pricecardParagraph {
    font-size: 16px;
    padding-right: 3px;
    padding-left: 3px;

    color: #5d6f84;
  }
}

@media screen and (max-width: 893px) {
  .priceCardContainer {
    background-color: white;
    border: 2px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
    border-radius: 10px;
    /*width: 300px;*/
    /*height: 200px;*/
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;

    /*text-align: center;*/

    /*margin:100px;*/
  }
  .pricetagBg_new {
    top: -10%;
  }

  .pricetagText_new {
    padding-top: 3px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding-top: 5px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;

    color: #ffffff;
  }

  .pricecardHeading {
    font-size: 35px;
    line-height: 25px;
    margin-top: 10px;
  }
  .pricecardParagraph {
    font-size: 16px;
    padding-right: 3px;
    padding-left: 3px;

    color: #5d6f84;
  }
}

@media screen and (max-width: 830px) {
  .pricetagBg_new {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pricecardHeading {
    font-size: 35px;
    line-height: 25px;
    margin-top: 10px;
  }
  .pricecardParagraph {
    font-size: 14px;
    padding-right: 3px;
    padding-left: 3px;

    color: #5d6f84;
  }
}

@media screen and (max-width: 767px) {
  .pricetagBg_new {
    padding-left: 30px;
    padding-right: 30px;
    top: -9%;
  }
  .pricetagText_new {
    padding-top: 3%;
    font-size: 14px;
  }

  .priceCardContainer {
    background-color: white;
    border: 2px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
    border-radius: 10px;
    /*width: 300px;*/
    /*height: 200px;*/
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;

    /*text-align: center;*/

    /*margin:100px;*/
  }

  .pricecardHeading {
    font-size: 45px;
    line-height: 25px;
    margin-top: 20px;
  }
  .pricecardParagraph {
    font-size: 16px;
  }
}

@media screen and (max-width: 550px) {
  .pricecardHeading {
    font-size: 50px;
    line-height: 25px;
    margin-top: 10px;
  }
  .pricecardParagraph {
    font-size: 18px;
  }
}

@media screen and (max-width: 450px) {
  .priceCardContainer {
    background-color: white;
    border: 2px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
    border-radius: 10px;
    margin-left: 10px;
    /*width: 300px;*/
    /*height: 200px;*/
    width: 100%;
    height: 150px;
  }
}
@media screen and (max-width: 987px) {
  .pricecardHeading {
    font-size: 20px;
    line-height: 28px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .discountPricecardHeading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;

    color: #5d6f84;
  }
  .lyftDiscount {
    del {
      color: #2584e0;
      text-decoration: none;
      position: relative;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 5px;

      &:before {
        content: " ";
        display: block;
        width: 100%;
        border-top: 3px solid rgba(255, 0, 0, 0.8); /* Corrected to use proper rgba format */
        height: 12px;
        position: absolute;
        bottom: 20%; /* Adjusted for proper positioning */
        left: 0;
        transform: rotate(10deg); /* Adjust the angle as needed */
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .pricetagBg_new {
    padding-left: 20px;
    padding-right: 20px;
    top: -10%;
  }
  .pricetagText_new {
    padding-top: 5%;
    font-size: 12px;
  }
  .pricecardHeading {
    font-size: 40px;
    line-height: 25px;
    margin-top: 10px;
  }
  .pricecardParagraph {
    font-size: 18px;
  }
}
