.produc-card-bg_img{
    /* background: url(../../Assets/carimg.png); */
    background-repeat: no-repeat;
    background-size: contain;
    width: 147px;
    height: 100px;
    margin-top: 20px;
    position: relative;
   
  }
  .camera-icon_container{
    margin-left: 4px;
    margin-top: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .product-name{
  color: black;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
  .product-type{
    color: #1262B1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }