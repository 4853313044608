.overlyCardWrapper{
    padding:10px
}
.cardContainer{

    /*max-width: 350px;*/
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    padding: 20px;

    height: 200px;
}
.cardIconImgs{
    width: 13%;
    padding-bottom: 10px;
}

.cardHeading{
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    margin: 0;
    color: #FFFFFF;
}
.description{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}
@media only screen and (max-width: 1024px) {
    .cardContainer{
        /*background-color: red;*/
        max-width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-right: 10px;
    }

}

@media only screen and (max-width:348px) {
    .cardHeading{
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        margin: 0;
        color: #FFFFFF;
    }
    .description{
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
    }

}
