.chexBannerContainer{
    background-image:linear-gradient(to right,#254f8e5b, #254f8e57) ,url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/formrectangle_agiisk_rishru.png');
    background-size: cover;
    background-position: center;
    margin-top: 150px;
    margin-bottom: 270px;
    height: 350px;
    display: flex;
    align-items: center;
}
.bannerContentContainer{

    padding-left: 100px;
    margin-right: 100px;
}
.headingBanner_ChexForm{

    /* width: 70%; */
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    font-family: Poppins;
    color:white;
}
.contentBold{
    font-family: "Roboto"!important;
    font-weight: 800;
    letter-spacing: 1px;
}
.bannerFormContainer{
    background-color: #EEEEEE;
    border-top: 5px solid #FF7A00;
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    position: absolute;
    /*top:10%;*/
    /*bottom:110%;*/
    /*left: 0%;*/
    margin-top:-260px;
    /*margin-right: 100px;*/
    /* width: 600px; */
    /* max-width: 650px; */
}
.headingFormChex{
    font-size: 26px;
    font-weight: 800;
    color:#3C3B3B;
}
.formContainer_chex{
    padding-top: 20px;

}
.namingSections_chex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input_field_chex {
    background: rgb(255, 255, 255) !important;
    border: 1px solid #C1C1C1 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
    margin: 0px auto 15px !important;
    padding: 15px 8px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: 'Poppins' !important;
    letter-spacing: 1px !important;

}

::placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

.terms_conditionsContainer{
    display: flex;
    flex-direction: row;
}

.termsConditionsText{
    padding-left: 10px;
    font-size: 16px;
}

.button-wrapper_chex {
    background:  #FF7A00!important;
    border-radius: 6px !important;
    border: 1px solid #FF7A00 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 32px !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 10px 15px !important;
    text-decoration: none !important;
    cursor: pointer !important;
    display: block;
    width: 100% !important;
    margin: 0px auto !important;
    height: 54px !important;
}

.newcheckmark_chex {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e3e2e2;
    border:1px solid grey;
    border-radius: 3px;
}

.register-checkbox-text_chex{
    color:black;
}
.newcontainer:hover input ~ .newcheckmark_chex {
    background-color: #ccc;

}
.newcontainer input:checked ~ .newcheckmark_chex {
    background-color: #fff;

}
.newcheckmark_chex:after {
    content: '';
    position: absolute;
    display: none;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border:1px solid transparent;
    background-color: #d3d3d3;

}
.newcontainer input:checked ~ .newcheckmark_chex:after {
    content: '\2713';
    display: block;

  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    /*margin-top: -10px;*/

    text-align: center;

    color: #ff7a00;
}


@media screen and (max-width: 1400px) {
    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 30px;
        padding-left: 40px;
        padding-right: 40px;
        position: absolute;
        margin-right: 10px;
        /*top:10%;*/
        /*bottom:110%;*/
        /*left: 0%;*/
        margin-top:-260px;
        /*margin-right: 100px;*/
        /* width: 600px; */
        /* max-width: 650px; */
    }
    .headingFormChex{
        font-size: 25px;
        font-weight: 800;
        color:#3C3B3B;
    }

}
@media screen and (max-width: 1300px) {
    .headingFormChex{
        font-size: 23px;
        font-weight: 800;
        color:#3C3B3B;
    }

    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 30px;
        padding-left: 30px;
        padding-right: 30px;
        position: absolute;
        margin-right: 40px;
        margin-top:-260px;
    }

}

@media screen and (max-width: 1799px) {
    .form-item-style-chex_first{
        width: 48%;
    }
}
@media screen and (max-width: 1225px) {
    .headingFormChex{
        font-size: 21px;
        font-weight: 800;
        color:#3C3B3B;
    }

}

@media screen and (max-width: 1150px) {
    .headingFormChex{
        font-size: 21px;
        font-weight: 800;
        color:#3C3B3B;
    }
    .bannerContentContainer{

        padding-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (max-width: 985px) {

    .headingFormChex{
        font-size: 18px;
        font-weight: 800;
        color:#3C3B3B;
    }
    .bannerContentContainer{

        padding-left: 40px;
        margin-right: 40px;
    }
    .headingBanner_ChexForm{

        /* width: 70%; */
        font-size: 17px;
        font-weight: 500;
        text-align: center;

        color:white;
    }
}


@media screen and (max-width: 842px) {
    .form-item-style-chex_first{
        width: 100%;
    }
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .headingFormChex{
        font-size: 16px;
        font-weight: 800;
        color:#3C3B3B;
    }
    .bannerContentContainer{

        padding-left: 40px;
        margin-right: 40px;
    }
    .headingBanner_ChexForm{

        /* width: 70%; */
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        color:white;
    }

}

@media screen and (max-width: 769px){
    .chexBannerContainer{
        background-image:linear-gradient(to right,#254f8e5b, #254f8e57) ,url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/formrectangle_agiisk_rishru.png');
        background-size: cover;
        background-position: center;
        margin-top: 20px;
        margin-bottom: 0px;
        padding:20px;
        position:relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;
        height: auto;
        /*display: block;*/
    }

    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 20px;


        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        /*margin-left: 0px;*/
        /*top:10%;*/
        /*bottom:110%;*/
        /*left: 0%;*/
        margin-top:0px;
        /*margin-right: 100px;*/
        /*width: 540px;*/
        /* max-width: 650px; */
    }
    .bannerContentContainer{
text-align: center;
        padding-left: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 0px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 24px;
        font-weight: 800;
    }
}


@media screen and (max-width: 600px){

    .bannerContentContainer{

        padding-left: 20px;
        padding-right: 20px;
        margin-right: 0px;
    }
    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 20px;
        /*margin-left: 10px;*/


        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        /*margin-left: 0px;*/
        /*top:10%;*/
        /*bottom:110%;*/
        /*left: 0%;*/
        margin-top:0px;
        /*margin-right: 100px;*/
        width: 100%;
        /* max-width: 650px; */
    }
}


@media screen and (max-width: 510px){
    .register-checkbox-text_chex{
        font-size: 12px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 20px;
        font-weight: 800;
    }

}

@media screen and (max-width: 444px){
    .headingBanner_ChexForm{
        font-size: 15px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 17px;
        font-weight: 800;
    }

}


@media screen and (max-width: 384px){
    .bannerContentContainer{

        padding-left: 10px;
        padding-right: 10px;
        margin-right: 0px;
    }
    .headingBanner_ChexForm{
        font-size: 15px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 14px;
        font-weight: 800;
    }

}

@media screen and (min-width: 1800px) {
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.login-landing-signup {
    display: "flex";
    margin-top: 15px;
}

@media screen and (min-width: 1440px) {
.login-landing-signup {
    display: "flex";
    margin-top: 3px;
}
}

@media screen and (min-width: 1800px) {
    .chexBannerContainer{
        background-image:linear-gradient(to right,#254f8e5b, #254f8e57) ,url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/formrectangle_agiisk_rishru.png');
        background-size: cover;
        background-position: center;
        margin-top: 150px;
        margin-bottom: 285px;
        height: 350px;
        display: flex;
        align-items: center;
    }
}

