.inspectionInstructionHeading {
  font-size: 17px;
  font-weight: 900;
  padding: 10px 10px 10px 7px;
  background-color: #F0F2F6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}
.inspectionInstructionContentContainer {
  display: flex;
  padding-top: 10px;
  background-color: white;
  border-radius: 10px;
  height: 77vh;
}
.inspectionInstructionContent {
  font-size: 14px;
  color: #000000;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  margin-top: 8px;
}
.carinspectionInstructionImg {
  width: 65px;
  height: 510px;
}
.inspectionInstructionContentHeading {
  font-size: 16px;
  color: #0B5EAF;
  font-weight: bolder;
  margin-top: 8px;
  font-family: 'Poppins', sans-serif;
}
.inspectionInstructionContentStyling {
  margin-top: 15px;
}
.inspectionInstructionContentText {
  font-size: 13px;
  color: #414b55;
  font-weight: 500;
}
.inpection-instruction-btn-container {
  position: fixed;
  bottom: 10px;
  width: 29.899%;
}
@media only screen and (max-width: 1024px) {
  .inpection-instruction-btn-container {
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .inpection-instruction-btn-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .inpection-instruction-btn-container {
    width: 100%;
    background-color: white;
    /* padding: 20px; */
    height: 15%;
    margin-bottom: -20px;
  }
}