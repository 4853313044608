.pricing_card_main_ride{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 5px 5px 10px #A6B4C8;
    border-radius: 20px;
    width: 180px;

}
.card_debose_wrap{

    border-radius: 15px;
    padding: 15px;
    padding-bottom:0px;
    box-shadow: inset 5px 5px 10px #d3d3d3;
    margin-bottom: 30px;

}
.image_debose_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -5px;
    width: 130px;
    height: 120px;

    /*background-color: rgba(255, 0, 0, 0.5);*/

}
.imageLyft{
    width: 40% !important;
}
.imageDouble{
    width: 40% !important;
}
.card_debose_section_wrap .image_debose_part_wrapper img{
    width: 53%;
    /*padding-bottom: 10px;*/
}
.card_debose_section_wrap .image_debose_part_wrapper .para_debose_point{
    font-size: 16px;
    padding-top: 3px;
}

.content_section_pricing_card_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    /*background-co lor: red;*/
}
 .heading_content_price{
    font-size: 45px;
    font-weight: 700;
    font-family: Poppins;
    color: #FF9900;
}
 .heading_content_price span{
     font-size: 30px;
 }

.content_section_pricing_card_wrapper .para_pricing_content{
    font-size: 12px;
    font-weight: 400;
    margin-top:-10px;
    font-family: Poppins;

}

@media screen and (max-width:713px) {
    .pricing_card_main_ride{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        box-shadow: 5px 5px 10px #A6B4C8;
        border-radius: 20px;
        width: 180px;

    }

}


@media screen and (max-width:548px) {
    .pricing_card_main_ride{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        box-shadow: 5px 5px 10px #A6B4C8;
        border-radius: 20px;
        width: 180px;

    }

    .imageLyft{
        width: 40% !important;
    }
    .imageDouble{
        width: 40% !important;
    }
    .card_debose_section_wrap .image_debose_part_wrapper img{
        width: 53%;
        /*padding-bottom: 10px;*/
    }
    .card_debose_section_wrap .image_debose_part_wrapper .para_debose_point{
        font-size: 16px;
        padding-top: 3px;
    }

}