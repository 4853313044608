.menu-image {
  height: 130px;
  background-size: cover;
  width: 100%;
  opacity: 0.7;
  background-image: url(../../Assets/testing.png);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.img-container {
  background-image: linear-gradient(to right, rgba(255, 154, 59, 0.8), rgba(255, 122, 0, 0.8));
}

.image-text {
  position: absolute;
  text-align: center;
  z-index: 1000 !important;
  font-size: 22px;
  font-weight: 900;
  color: white !important;
}

.icon-bar {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  padding: 14px;
  color: black;
  align-items: center;
  cursor: pointer;
}

.icon-text {
  margin-left: 10px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.side-bar-close_container {
  border: 3px solid white;
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.bottom_text{
  position: absolute;
  bottom: 10px;
  color: #1b68b3;
  font-size: 24px;
  margin-left: 40%;
  font-weight: bold;
}
.referral_text{
  position: absolute;
  bottom: 10px;
  color: #1b68b3;
  font-size: 15px;
  margin-left: 30%;
  font-weight: bold;
}