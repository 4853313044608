:global(.rec-carousel) {
  background-color: #f9f9f9 !important;
  padding-top: 0px !important;
}
/* 
:global(.rec-arrow) {
  margin-bottom: 300px;
}

:global(.rec-arrow-right) {
  top: 40%;
  right: 6%;
}

:global(.rec-arrow-left) {
  left: 6%;
  top: 40%;
}

:global(.rec-arrow:hover) {
  background-color: #f4f9ff !important;
  color: black !important;
}

:global(.rec-arrow:focus) {
  background-color: #f4f9ff !important;
  color: black !important;
}

.review-card-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-card-container-blue {
  background-color: #438be3;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-text {
  font-size: 16px;
  color: #333;
}

.name-rate-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.reviewer-name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: -5px;
} */
