.reset-main-container{
    width: 100%;
    position: relative;
}
.reset-image-background-signin{
    position: relative;
    width: 100%;
    background: url('../../Assets/bg_img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.reset-arrow-back {
  color: #fff;
}
@media screen and (min-width: 768px) {
    .reset-image-background-signin {
      background-size: cover;
      background-position: center center;
    }
  }
  .reset-main-bg-signin{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 0;
  }
  .reset-header-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  .reset-arrow-back{
    color: #fff;
  }
  .reset-arrow-back a{ 
    color: #fff;
  }

  .reset-content-vehicle-inspection{
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    color: #ffffff;
    align-self: center;
    margin: 0 auto;
  }
  .reset-main-screen{
    font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #4a93e9;
  text-align: center;
  margin: 40px auto;
  }
  .reset-content-footer-area-signin{
    text-align: center;
    /* align-items: center; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100vh;
    display: flex;
    align-items: center;
  }