.certificate-input-conatainer {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.certificate-input-conatainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.fail-checkbox {
  margin-left: 10px;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  border: 1px solid black;
  width: 18px;
  background-color: #fff;
}
/* On mouse-over, add a grey background color */
.certificate-input-conatainer:hover input ~ .checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.certificate-input-conatainer input:checked ~ .checkmark {
  background-color: #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.certificate-input-conatainer input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.certificate-input-conatainer .checkmark:after {
  left: 3px;
  top: -7px;
  width: 10px;
  height: 22px;
  border: solid #fc6522;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media only screen and (max-width: 430px) {
  .vehicle-milage {
    font-size: 14px;
    margin-left: -5px;
  }
  .lisence-plate {
    font-size: 14px;
    margin-left: 20px;
  }
  .vin-inspection {
    font-size: 14px;
    margin-left: 20px;
  }
  .vehicle-make {
    font-size: 14px;
    margin-left: -5px;
  }
  .vehicle-model {
    font-size: 14px;
    margin-left: 20px;
  }
  .vehicle-year {
    font-size: 14px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 370px) {
  .vehicle-milage {
    font-size: 12px;
    margin-left: -30px;
  }
  .lisence-plate {
    font-size: 12px;
    margin-left: 15px;
  }
  .vin-inspection {
    font-size: 12px;
    margin-left: 15px;
  }
  .vehicle-make {
    font-size: 12px;
    margin-left: -30px;
  }
  .vehicle-model {
    font-size: 12px;
    margin-left: 15px;
  }
  .vehicle-year {
    font-size: 12px;
    margin-left: 15px;
  }
}
.fontFamily {
  font-family: Roboto Slab;
  font-weight: 400;
}
