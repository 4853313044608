.inspection-tile{
    cursor: pointer;
    width: 90%;
    /* height: 127px; */
    padding: 10px ;
    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px;
    /* margin-left: 20px; */
    position: relative;
  
  }
  .inspection-tracking-id-text{
      margin-left: 15px;
      margin-top: 10px;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #1468BA;
  }
  .inspection-tracking-id{
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      margin-top: 15px;
      margin-left: 25px;
      color: #000000;
  }
  .inspection-tile-content{
      display: flex;
     
  }
  .inspection-date-created{
      margin-left: 50px;
      margin-top: 10px;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #1468BA;
  }
  .inspection-date{
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      margin-top: 15px;
      margin-left: 60px;
      color: #000000;
  
  }
  .progress-close-icon{
    position: absolute;
    right: 5px;
    top: 5px; 
}