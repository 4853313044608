.intro-main-container {
  width: 100%;
}
.intro-imageBackground-main {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: url(../../Assets//bg_img.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
@media screen and (min-width: 768px) {
  .intro-imageBackground-main {
    background-size: cover;
    background-position: center center;
  }
}
.intro-mainBg {
  background: linear-gradient(0deg, rgba(9, 11, 12, 0) 50%, #1b68b3 99.97%);
  position: relative;
  width: 100%;
  height: 100%;
}
.thank-contentMain-screen {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 90%;
}
.intro-headingH5 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #74b3fb;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0px;
}
.intro-headingH1 {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 0px;
  width: 100%;
}
.intro-headingSpan {
  color: #ff9900;
}
.intro-contentP {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;

}
.intro-content-footer-area-intro {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-validation-text {
  text-align: center;
  color: white;
}
.intro-state-name {
  color: #ff7400;
}
.intro-MtB5 {
  /* margin-bottom: 42px; */
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-signinBtn {
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 8px 60px 15px;
  text-decoration: none;
  width: 260px;
}

.thankyou-contentP {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: white;
}
.thankyou-chex-contentP {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: white;
}
.thank-you-paragrpah {
  font-size: 14px;
  color: #b2c7f0;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.thank-text-contaner {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .intro-signinBtn {
    width: 80%;
  }

  .thank-text-contaner {
    width: 100%;
  }
}
.intro-contentDiv-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.intro-signUpWith-email {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: none;
  padding: 12px 52px;
  text-decoration: none;
  width: 260px;
}
@media screen and (max-width: 600px) {
  .intro-signUpWith-email {
    width: 80%;
  }
}
.intro-signUpWith-email:active {
  outline: none;
  box-shadow: 0 0 0 0 !important;
}
.intro-signUpWith-email:focus {
  outline: none;
  box-shadow: 0 0 0 0 !important;
}
