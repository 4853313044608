.faqsWrapper{
    /*background-color: red;*/
    padding:15px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.questionStyleWrapper{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.faqsQuestionStyle{
    font-size: 16px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    /*font-family: Roboto;*/
}
.answer{

    font-size: 14px;
    padding-top: 15px;
}


@media screen and (max-width: 460px){
    .faqsQuestionStyle{
        font-size: 14px;

    }
    .answer{

        font-size: 12px;

    }


}