.App {
  text-align: center;
}

* {
  font-family: 'Poppins';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-modal-wrap {
  z-index: 100000000000000 !important;
  overflow: scroll ;
}

.input-field {
  background: rgb(231, 238, 245) !important;
  border: 1px solid rgb(231, 239, 248) !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100% !important;
  margin: 0px auto 14px !important;
  padding: 12px 14px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: 'Poppins' !important;
  line-height: 24px !important;
  letter-spacing: 1px !important;
}

.form-container {
  width: 66%;
}
.button-wrapper {
  background:  #FF7A00!important;
  border-radius: 100px !important;
  border: 1px solid #FF7A00 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 10px 15px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  width: 260px !important;
  margin: 0px auto !important;
  height: 54px !important;
}


.ant-input-affix-wrapper > input.ant-input {
  background: transparent;
}

.ant-select-selector {
  background: transparent !important;
  border: none !important;
  text-align: left !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

/* .form-item-style > .ant-form-item-control {
  margin-bottom: 10px;
} */

.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
  box-shadow: none !important;
  font-family: 'Poppins' !important;
  font-size: 16px !important;
}
.react-stars-wrapper-002155496152759251:focus {
  border: none !important;
}
@media screen and (min-width: 600px) {
  .form-container {
    width: 66%;
  }
  .button-wrapper {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .form-container {
    width: 40%;
  }
}

@media screen and (min-width: 1024px) {
  .form-container {
    width: 30%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
