.imageModal .ant-modal-header {
  display: none;
}

.imageModal .ant-modal-footer {
  display: none;
}

.imageModal .ant-modal-body {
  padding: 5px;
}

.imageModal .ant-modal-close-icon {
  color: #d07575;
  font-weight: bold;
  font-size: 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.sure-modal .ant-modal-header {
  display: none;
}

.sure-modal .ant-modal-footer {
  display: none;
}

.sure-modal .ant-modal-content {
  border-radius: 15px;
  height: 60% !important;
  width: 60% !important;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.d-flex-1 {
  display: flex;
}

.progressNumber {
  color: blue;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  width: 100% !important;
  text-align: center !important;
}

.progressNumber .progress-bar {
  text-align: center !important;
  width: 100% !important;
}

.sure-modal .ant-modal-content .ant-modal-close {
  display: none;
}

.signature-data {
  width: 40%;
}

@media only screen and (max-width: 600px) {
  .signature-data {
    width: 100% !important;
  }

}



@media only screen and (max-width:1000px) {

  .sure-modal .ant-modal-content {
    border-radius: 15px;
    height: 60% !important;
    width: 50% !important;
  }
}

@media only screen and (max-width:768px) {

  .sure-modal .ant-modal-content {
    border-radius: 15px;
    height: 80% !important;
    width: 70% !important;
  }
}

@media only screen and (max-width:450px) {

  .sure-modal .ant-modal-content {
    border-radius: 15px;
    height: 55% !important;
    width: 85% !important;
  }

  .yes-btn-container {
    padding: 10px !important;
    font-size: 14px !important;
  }

  .no-btn-container {
    padding: 10px !important;
    font-size: 14px !important;
  }
}

.yes-btn-container {
  background-color: orange;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 100px;
  color: white;
  margin-top: 20px;
  font-size: 16px;
}

.no-btn-container {
  border: 2px solid orange;
  justify-content: center;
  align-items: center;
  padding: 13px;
  border-radius: 100px;
  margin-top: -20px;
  color: black;
  font-size: 16px;
}