@import "~antd/dist/antd.css";

.menuBar {
  padding: 0 20px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 5;
}
.ScrollBackColor {
  width: 100%;
  background-color: #fff;
  transition: 0.4s;
}
.logo_home img {
  width: 80% !important;
  padding-bottom: 8px;
  /* padding-left: 60px; */
}
.login_text_ankr {
  background-color: #fff;
  border: 2px solid #ff7a00;
  color: #ff7a00;
  font-family: Poppins;
  font-weight: bold;
  border-radius: 5px;

  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 780px) {
  .menuBar {
    padding: 0 0px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 5;
  }
  .ScrollBackColor {
    padding: 0px;
  }
  .logo_home img {
    width: 80% !important;
  }
}

@media screen and (max-width: 767px) {
  .menuBar {
    padding: 0 20px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 5;
  }
  .ScrollBackColor {
    padding-right: 30px;
  }
  .logo_home img {
    width: 70% !important;
    padding-bottom: 8px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 450px) {
  .logo_home img {
    width: 80% !important;
    padding-bottom: 8px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 406px) {
  .logo_home {
    width: 75%;
  }
  .logo_home img {
    width: 100% !important;
  }
  .menubar {
    padding: 10px !important;
  }
}
@media screen and (max-width: 370px) {
  .logo_home {
    width: 80%;
  }
  .logo_home img {
    width: 100% !important;
    padding-bottom: 8px;
    padding-left: 16px;
  }
  .menubar {
    padding: 10px !important;
  }
}
@media screen and (max-width: 340px) {
  .logo_home {
    width: 83%;
  }
  .logo_home img {
    width: 100% !important;
    padding-bottom: 8px;
    padding-left: 10px;
  }
  .menubar {
    padding: 10px !important;
  }
}

.ant-menu-item-selected {
  color: #1468ba !important;
  border: none !important;
  /*border-bottom: 4px solid red!important;*/
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 0px solid rgba(49, 44, 44, 0.133);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1468ba !important;
}
.menuCon .leftMenu a:hover {
  color: #1468ba !important;
  text-decoration: none !important;
  border-bottom: 0px solid #1468ba !important;
}
.ankrPricing {
  color: #1468ba !important;
  text-decoration: none !important;
  border-bottom: 0px solid #1468ba !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
  border: none !important;
}
.ant-menu-horizontal:hover > .ant-menu-item::after {
  border-bottom: none !important;
}
.ant-menu-submenu::after {
  border-bottom: none !important;
}
@media screen and (max-width: 500px) {
  .menuBar {
    width: 100%;
  }
}
.logo_home {
  /*width: 150px;*/
  float: left;
  margin-top: 5px;
}

.header_logo_text {
  color: white;
}

.logo_home img {
  margin-top: 5px;
  width: 100%;
}
.menuNavbarMain {
  background-color: transparent;
  color: white !important;
}
.ant-menu-submenu-title:hover {
  color: #ff7a00 !important;
}
.ant-menu-submenu:hover {
  color: #ff7a00 !important;
  border-bottom: none !important;
}
.ant-menu-submenu-popup:hover > .ant-menu-submenu-title {
  color: #ff7a00 !important;
}
.ant-menu-sub {
  background-color: black !important;
}
/*.menuNavbarMain:hover{*/
/*  color:#FF7A00!important;*/

/*}*/
@media screen and (max-width: 768px) {
  .ant-menu-sub {
    background-color: #1b223b !important;
  }
  .ant-menu-submenu-arrow {
    color: white !important;
  }
}
.menuItemClr {
  color: white !important;
  text-decoration: none;
}
.dropDownMenuWrap {
  background-color: black;
  color: white;
}
.dropDownMenuWrap:hover {
  color: #ff7a00 !important;
  /* border-bottom: 1px solid #FF7A00; */
  /*border-bottom:3px solid #FF7A00!important;*/
}
.linkNavbar_wrap_menu {
  color: white !important;
}
.linkNavbar_wrap {
  /* color: white!important; */
}

/* .linkNavbar_wrap:hover{
 color: red!important;
} */
/*.logo a {*/
/*  display: inline-block;*/
/*  font-size: 20px;*/
/*  color:red;*/
/*  padding: 19px 20px;*/
/*  text-transform: capitalize;*/
/*}*/

.menuCon {
  /*width: calc(100% - 0px);*/
  float: right;
  padding-top: 10px;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: right;
}

.menuCon .leftMenu a {
  color: #000;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: black;
  border: none;
  outline: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: white;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: white;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo_home a {
    padding: 10px 20px;
  }
}

/* @media  screen and (max-width: 1124px) {
  .menuBar{
    width: 98%;
  }

} */

@media screen and (max-width: 1180px) {
  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 5px;
  }
  .menuBar {
    width: 100%;
  }
}

@media screen and (max-width: 1345px) {
  .menuBar {
    width: 100%;
  }
  .menuCon .my-menu-item {
    font-size: 14px;
    padding: 0px 16px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}
@media screen and (max-width: 1190px) {
  .menuBar {
    width: 100%;
  }
  .menuCon .my-menu-item {
    font-size: 14px;
    padding: 0px 10px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}
@media screen and (max-width: 1070px) {
  .menuBar {
    width: 100%;
  }
  .menuCon .my-menu-item {
    font-size: 10px;
    padding: 0px 8px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}
@media screen and (max-width: 998px) {
  .menuBar {
    width: 100%;
  }
  .menuCon .my-menu-item {
    font-size: 10px;
    padding: 0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}

@media screen and (max-width: 975px) {
  .menuBar {
    width: 100%;
  }

  .menuCon .my-menu-item {
    font-size: 9px !important;

    padding: 0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}
@media screen and (max-width: 924px) {
  .menuBar {
    width: 100%;
  }

  .menuCon .my-menu-item {
    font-size: 8px !important;

    padding: 0px 4px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}
@media screen and (max-width: 836px) {
  .menuBar {
    width: 100%;
  }
  .menuCon .my-menu-item {
    font-size: 8px !important;
    padding: 0px 2px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap {
    padding: 0px;
  }
}

/* @media  screen and (max-width: 1175px) {
  .menuBar{
    width: 100%;
  }
  .menuCon .my-menu-item{
     font-size: 12px;
    padding:0px 16px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }
}
@media  screen and (max-width:975px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 12px;
    padding:0px 10px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:878px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 9px;
    padding:0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:875px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 10px;
    padding:0px 9px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:822px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 8px;
    padding:0px 6px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

} */
@media screen and (max-width: 960px) {
  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 1px;
  }
  .menuBar {
    width: 100%;
  }
}

@media screen and (max-width: 916px) {
  /* .menuBar{
    width: 100%;

  } */
  .logo_home img {
    width: 80%;
  }
  /* .menuCon .my-menu-item{
    font-size: 12px;
    padding:0px 9px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  } */
}

/* @media  screen and (max-width:874px) {
  .menuBar{
    width: 100%;


  }
  .logo_home img{
    width: 50%;

  }
  .menuCon .my-menu-item{
    font-size: 8px;
    padding:0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

  .menuCon .ant-menu-item {
    padding: 0px 1px;
  }

  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

} */

@media screen and (max-width: 833px) {
  /* .menuBar{
    width: 100%;

  } */
  .logo_home img {
    width: 70%;
  }
  /* .menuCon .my-menu-item{
    font-size: 10px;
    padding:0px 6px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  } */

  .menuCon .ant-menu-item {
    padding: 0px 1px;
  }

  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 575px) {
  .logo_home img {
    width: 60% !important;
  }
  .barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    /*margin-top: 8px;*/
    margin-bottom: 12px;
    /*display: none;*/
    /*background: none;*/
  }
  .menuBar {
    /* width: 100%; */
    margin-left: 0px;
  }
}

/*.ant-menu-horizontal:hover {*/
/*  line-height: 46px;*/
/*  border: 0;*/
/*  border-bottom: 1px solid #57c717!important;*/
/*  box-shadow: none;*/
/*}*/

/*.ant-menu-item{*/
/*  border-bottom:3px solid green;*/

/*}*/

.styling_ankr_pricing {
  /*color: #ffffff!important;*/
}
.linkNavbar_wrap_pricing {
  color: #000000 !important;
}
.linkNavbar_wrap_pricing:hover {
  color: #1468ba !important;
}
.my-menu-item {
  padding: 0 25px;
}
@media screen and (max-width: 768px) {
  .linkNavbar_wrap_pricing {
    color: #ffffff !important;
  }
}

.my-menu-item:hover {
  color: #ff7a00 !important;
}

.dropDown_newnav {
  background-color: black;
  padding-bottom: 10px;
  padding-top: 10px;
}
.menu-items_newnav {
  margin-top: 10px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #ff7a00 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  color: white !important;
  background: #ff7a00 !important;
}

.ant-menu-submenu-title:hover {
  color: white !important;
}

@media screen and (max-width: 840px) {
  .my-menu-item {
    padding: 0 10px !important;
  }
}
