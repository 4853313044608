.solution-container {
  width: 100%;
  background-color: #ffffff;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.solution-section {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solution-title {
  font-size: 35px;
  font-weight: 500;
  text-align: left;
  color: #1467b8;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 10px;
}

.cards-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.solution-card {
  flex: 1 1 30%; /* Allow cards to be flexible and take up 30% of the container */
  max-width: 360px; /* Ensure cards do not exceed a certain width */
  background: #f5f5f5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px; /* Adds margin to each card */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.solution-card img {
  width: 100%;
  max-width: 211px;
  height: auto;
  padding: 10px;
  margin-bottom: 10px;
}

.solution-card-title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #f68b1f;
  margin-bottom: 10px;
}

.solution-card-text {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 1030px) {
  .solution-title {
    font-size: 30px;
    font-weight: 400;
  }

  .solution-card {
    flex: 1 1 45%; /* Adjust card width for smaller screens */
    max-width: 100%;
  }

  .solution-card img {
    width: 160px;
    height: auto;
    padding: 8px;
  }

  .solution-card-title {
    font-size: 20px;
    font-weight: 400;
    padding: 8px;
  }

  .solution-card-text {
    font-size: 20px;
    font-weight: 400;
    padding: 8px;
  }
}

@media screen and (max-width: 767px) {
  .solution-title {
    font-size: 26px;
    font-weight: 400;
    text-align: center;
  }

  .solution-card {
    flex: 1 1 100%; /* Make cards take full width on very small screens */
    width: 360px;
    padding: 20px;
  }

  .solution-card img {
    width: 140px;
    height: auto;
    padding: 6px;
  }

  .solution-card-title {
    font-size: 18px;
    font-weight: 400;
    padding: 6px;
  }

  .solution-card-text {
    font-size: 18px;
    font-weight: 400;
    padding: 6px;
  }
}
