.login-main-container {
  width: 100%;
  position: absolute;
  height: 100%;
}

.login-image-background-signin {
  position: relative;
  width: 100%;
  background: url('../../Assets/bg_img.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.confirm-email-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 20px 0;
}

.confirm-email-loader {
  margin-top: 20px;
}

.login-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

.login-title-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #74b3fb;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 30px;
}

.login-back-arrow {
  color: #fff;
  position: absolute;
  left: 30px;
  top: 30px;
}
a {
  color: #fff;
}

.login-arrow-link {
  color: #fff;
}

.login-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 50px 0 0 0;
}
.logo-span-color {
  color: #ff9900;
}

.login-desp-heading {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
}

.login-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
}

.login-form-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
}

.changes-paragraph{
  color: white;
    text-align: center;
    margin-top: 33px;
    font-size: 17px;
    margin-bottom: 33px;
}

@media screen and (min-width: 768px) {
  .login-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}
