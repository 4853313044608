/* Existing styles */
.centered-section-benifits {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.benefits-text {
  color: #1467b8;
  font-size: 35px;
  font-weight: 500;
  line-height: 60.3px;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 420px;
  height: 280px;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 0px;
  opacity: 1; /* Set to 1 to make it visible */
}

.circle {
  margin-top: 20px;
  width: 70px;
  height: 70px;
  background-color: #f68b1f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adjust padding for spacing */
}

.circle img {
  width: 40px;
  height: 40px;
  object-fit: contain; /* Ensures the image fits within the circle */
  border-radius: 50%; /* Ensures image fits the circular container */
}

.card-title {
  margin-top: 15px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.card-paragraph {
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 16px;
  text-align: center;
}

/* Media queries */
@media screen and (max-width: 767px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    max-width: 360px; /* Apply the specified width for mobile view */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid rgb(199, 199, 199);
    margin: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .card-paragraph {
    font-size: 12px;
  }

  .circle {
    width: 40px;
    height: 40px;
  }

  .circle img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 720px) {
  .card {
    width: 100%;
    max-width: 360px; /* Apply the specified width for mobile view */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid rgb(199, 199, 199);
    margin: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }

  .benefits-text {
    font-size: 26px;
    line-height: 50px;
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .card-paragraph {
    font-size: 11px;
  }

  .circle {
    width: 35px;
    height: 35px;
  }

  .circle img {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 100%;
    max-width: 360px; /* Apply the specified width for mobile view */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid rgb(199, 199, 199);
    margin: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .card-paragraph {
    font-size: 10px;
  }

  .circle {
    width: 30px;
    height: 30px;
  }

  .circle img {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 550px) {
  .card {
    width: 100%;
    max-width: 360px; /* Apply the specified width for mobile view */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid rgb(199, 199, 199);
    margin: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .card-paragraph {
    font-size: 9px;
  }

  .circle {
    width: 25px;
    height: 25px;
  }

  .circle img {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 500px) {
  .card {
    width: 80%;
    max-width: 360px; /* Apply the specified width for mobile view */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid rgb(199, 199, 199);
    margin: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }

  .card-title {
    font-size: 20px;
    font-weight: 500;
  }

  .card-paragraph {
    font-size: 12px;
  }

  .circle {
    width: 40px;
    height: 40px;
  }

  .circle img {
    width: 20px;
    height: 20px;
  }
}
