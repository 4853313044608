.input-field {
  background: rgb(231, 238, 245) !important;
  border: 1px solid rgb(231, 239, 248) !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100% !important;
  margin: 0px auto 15px !important;
  padding: 15px 8px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 1px !important;
}
