.in-review-tile{
    background: #fff !important; ;
    cursor: pointer;
    width: 90%;
    border-bottom: none !important;
    /* height: 127px; */
    
    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px !important;
    padding-bottom: 16px;
    margin-top: 30px !important;
    position: relative;
  }
  .in-review-tracking-id-text{
    margin-left: 15px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
  }
  .in-review-tracking-id{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 25px;
    color: #000000;
    margin-bottom: 2px;
  }
  .in-review-tile-content{
      display: flex;
     
  }
  .in-review-date-created{
    margin-left: 35px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
  }
  .in-review-date{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 48px;
    color: #000000;
  
  }

  .submitted-tile-tag-ready-review{
    border: 1px solid #ff8d0d;
    background-color: #ff8d0d;
    background: cover;
    /* padding: 0px 20px; */
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #fff;
    width: 74px;
    height: 15px;
    text-align: center;
    font-size: 10px;
    margin-right: -1px;
  }