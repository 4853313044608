.contact_headingContainer{
  background-color: transparent;
  /* padding:10px; */
  /* padding-top: 20px; */
  padding-bottom: 20px;
  text-align: center;
}
.contactText{
  font-size: 40px;
  padding-top: 100px;
  color: black;

}
.form-container_contact{
  width: 100%;
}

.main_contactWrap{ 
  background-size: cover;
  background-repeat: no-repeat;
}

.feedbackContainerMain{
  background-color: #fff;
  margin-top: 30px;

  padding:20px;
  margin-left: 0px;
  padding-right: 0px;
}
.feedbackHeading{
  font-weight: 700;
  font-family: Roboto;
  letter-spacing: 2px;
  font-size: 35px;
}
.paragraphfeedback{

  font-family: Roboto;
  letter-spacing: 2px;
  padding-right: 10px;


  font-size: 16px;
  color: #716f6f;
}



.input-field_contactUs{
  background:white !important;
  border-bottom: 1px solid #E3E7F2 !important;
  border-radius: 1px !important;
  width: 100% !important;
  margin: 0px auto 15px !important;
  padding: 15px 8px !important;
  font-style: normal !important;
  box-shadow: none;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 1px !important;
}
.text_area_contact{
  width: 100% !important;
}
::placeholder{
  color:#585757 !important;
  padding-left:10px;
  outline: none;

}
.button-wrapper_contactus{
  background-color: #fff!important;
  border-radius: 0px!important;
  border: 2px solid black;
  margin-top:20px;
  margin-bottom: 20px;
  font-style: normal;
  height: auto!important;
  font-weight: 900;
  font-size: 15px;
  text-align: center;
  color: black !important;
  padding: 16px 70px;
  text-decoration: none;
  cursor: pointer;
}
.button-wrapper_contactus:hover{
  transition: 0.8s;
  background-color: #000 !important;
  border: 2px solid black;
  color: white !important;
}
.button-wrapper_contactus:focus {
  border: 2px solid black;
}

.button-wrapper_contactus-loading{
  background-color: #fff!important;
  border-radius: 0px!important;
  border: 2px solid black;
  margin-top:20px;
  margin-bottom: 20px;
  font-style: normal;
  height: auto!important;
  font-weight: 900;
  font-size: 15px;
  text-align: center;
  color: black !important;
  padding: 14px 85px;
  text-decoration: none;
  cursor: pointer;
}
/* .button-wrapper_contactus-loading:hover{
  transition: 0.8s;
  background-color: #000 !important;
  border: 2px solid black;
  color: white !important;
} */
/* .button-wrapper_contactus-loading:focus {
  border: 2px solid black;
} */
/* @media screen and (max-width: 1440px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 400px;
  }

}

@media screen and (max-width: 1100px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 200px;
  }

}
@media screen and (max-width: 1033px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 100px;
  }

} */
/* @media screen and (max-width: 1100px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 60p/x;
  }

} */

@media screen and (max-width: 767px) {
  .paragraphfeedback{
    text-align: center;
    font-size: 13px;
    padding-right: 10px;
  }

  .feedbackContainerMain{
    margin-left: 30px;
    margin-right: 30px;
    /*padding-right: 200px;*/
  }
  .form-container_contact{
    width: 100%;
  }


}

@media screen and (max-width: 608px){
  .input-field_contactUs{
    width: 80% !important;
  }
  .feedbackHeading {
    font-size: 24px;
  }
  .contactText {
    font-size: 30px;
    padding-top: 70px;
  }

}
@media screen and (max-width: 555px){
  .input-field_contactUs{
    width: 100% !important;
  }




  .feedbackContainerMain{
    margin-left: 10px;
    margin-right: 10px;
    padding:10px;
    /*padding-right: 20px;*/
  }

}
.input-field-style {
  background-color: white;
  border-bottom: 1px solid black;
  box-shadow: none;
  border-radius: 0px;
  padding: 4px;
  margin-top: 0px;
}
.input-field-style:focus {
  border: 1px solid black;
  box-shadow: -1px 2px 14px 2px rgba(0,0,0,0.07);
}
.input-field-label-contact-us {
  font-weight: 900;
  font-size: 16px;
  margin-top: 5px;
}
.contact-us-textArea {
  resize: none;
}
.contact-us-textArea:focus {
  box-shadow: -1px 2px 14px 2px rgba(0,0,0,0.07);
  outline: none;
}
.recapcha_desgin {
  margin-top: 30px;
}

.shahveer {
  color: red;
}