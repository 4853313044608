
.gotoarticle :hover{
    color: #1468BA !important ;
}
.lisy{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.gotoarticle{ 
    color: rgb(26, 25, 25); 
    cursor: pointer;
    float:right;
}
.main--containerS{
    margin-left:3%;
}
.main--containerS h1{
    margin-left:3%;
}
.blog-card-manage
{
   height:470px;
   width: 91%;
   margin-top: 7%;
   margin-bottom: 20px;
}
.blog-card-manage:hover {
    box-shadow: 0 2px 8px rgb(228, 228, 231); 
  }
.publishbyat{
     display: flex; justify-content: flex-end
}
@media only screen and (max-width:991px) {
    .blog-card-manage
    {
       /* height:650px; */
       left:0%;
       width: 96%;
       margin-top: 7%;
       /* border-radius: 20px; */
    }
    .main--containerS{
        margin-left:3%;
    }
    
}

@media only screen and (max-width:576px) {
    .publishbyat{
        display: flex; justify-content: flex-start
   }
    
}


    