.key_benefits_main_outer{
    background-color: #EBF7FA;
    padding: 30px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.key_benefits_image_wrapper{
    width: 100%;
}

.key_benefits_image_wrapper img{
    padding: 20%;
    width: 100%;

}

.key_benefits_content_wrapper_inner{
    display: flex;
    flex-direction: column;
    width: 100%;
  padding-right: 100px;
}
.key_benefits_content_wrapper_inner h2{
    font-size: 35px;
    font-family: Poppins;
    font-weight: bold;
    margin-bottom: 40px;
}

.key_benefits_content_wrapper_inner h2 span{
color: #FF9900;

}

.key_benefits_content_wrapper_inner .simple_info_benefits{
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    color: #1B1C1F;
    padding-left: 30px;
}
.key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
    background-color: #1468BA;
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    color: #ffffff;
    padding: 10px;
    padding-left: 30px;
    border-radius: 10px;

}


@media screen and (max-width: 1050px) {

    .key_benefits_content_wrapper_inner{
        padding-right: 50px;
    }

    .key_benefits_content_wrapper_inner .simple_info_benefits{
        font-size: 16px;

    }
    .key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
        font-size: 16px;
    }

}


@media screen and (max-width: 860px) {
    .key_benefits_image_wrapper{
        width:70%;
    }

    .key_benefits_image_wrapper img{
        padding: 20%;
        width: 100%;

    }

    .key_benefits_content_wrapper_inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 100px;
    }

    .key_benefits_content_wrapper_inner{
        padding-right: 20px;
    }

    .key_benefits_content_wrapper_inner .simple_info_benefits{
        font-size: 16px;

    }
    .key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
        font-size: 16px;
    }

}


@media screen and (max-width:689px) {
    .key_benefits_main_outer{
        background-color: #EBF7FA;
        padding: 30px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .key_benefits_image_wrapper{
        width:60%;
    }

    .key_benefits_image_wrapper img{
        padding: 10%;
        width: 100%;

    }



}


@media screen and (max-width: 450px) {
    .key_benefits_image_wrapper{
        width:70%;
    }

    .key_benefits_image_wrapper img{
        padding: 5%;
        width: 100%;

    }

    .key_benefits_content_wrapper_inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 0px;
    }

    .key_benefits_content_wrapper_inner{
        padding-right: 0px;
    }

    .key_benefits_content_wrapper_inner .simple_info_benefits{
        font-size: 14px;

    }
    .key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
        font-size: 14px;
    }

}