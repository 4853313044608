/* .transcation-container {
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; }*/
.checkout-container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%),
    #191a1b;
  /*height: 100vh !important;*/
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
}
.overlayLoader {
  opacity: 0.7;
}
.CheckOut-container {
  position: relative;
}
/*.anticon.ant-input-clear-icon{*/
/*  margin: 0 4px;*/
/*  color: gray !important;*/
/*  font-size: 26px!important;*/
/*  vertical-align: -1px;*/
/*  !*background-color: yellow!important;*!*/
/*  cursor: pointer;*/
/*  transition: color 0.3s;*/
/*}*/

.inputPromoDesign {
  cursor: caret;
  border-radius: 4px !important;
  box-sizing: border-box;
  padding-right: 25px;
  border: 2px solid #f1f1f1;
  height: 40px;
  font-size: 14px;
  background-color: #f1f1f1;
  /*width: 200px;*/
  font-family: Poppins;
  font-weight: 600;
  margin-left: 10px;
}

.flex_block {
  display: inline-block;
}

.inputPromoDesign::placeholder {
  /*font-size: 1px!important;*/
  color: gray;
}

.button_apply_style {
  margin-left: 10px;
  width: 86px;
  height: 38px;
  background-color: #1468ba;
  border-radius: 5px;
  border: 0px;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .inputPromoDesign {
    cursor: caret;
    border-radius: 4px !important;
    box-sizing: border-box;
    padding-right: 25px;
    border: 2px solid #f1f1f1;
    height: 40px;
    font-size: 14px;
    background-color: #f1f1f1;
    width: 200px;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .flex_block {
    display: block;
  }
  .buttonWrapper_style {
    /*background-color: red;*/
    margin-right: 21px;
  }
  .inputPromoDesign {
    cursor: caret;
    border-radius: 4px !important;
    box-sizing: border-box;
    padding-right: 25px;
    border: 2px solid #f1f1f1;
    height: 40px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-right: 10px;
    width: 100%;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 10px;
  }

  .button_apply_style {
    margin-left: 10px;
    /*width:86px;*/
    height: 38px;
    background-color: #1468ba;
    border-radius: 5px;
    width: 100%;
    margin-right: 240px !important;
    margin-bottom: 20px;
    border: 0px;
    color: #ffffff;
    text-transform: capitalize;
    font-size: 14px;
  }
}

@media screen and (max-width: 376px) {
  .inputPromoDesign {
    cursor: caret;
    border-radius: 4px !important;
    box-sizing: border-box;
    padding-right: 25px;
    border: 2px solid #f1f1f1;
    height: 40px;
    font-size: 14px;
    background-color: #f1f1f1;
    width: 100%;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 10px;
  }
}

@media screen and (max-width: 328px) {
  .inputPromoDesign {
    cursor: caret;
    border-radius: 4px !important;
    box-sizing: border-box;
    padding-right: 25px;
    border: 2px solid #f1f1f1;
    height: 40px;
    font-size: 14px;
    background-color: #f1f1f1;
    width: 100%;
    font-family: Poppins;
    font-weight: 600;
    margin-left: 10px;
  }
}

.iconClear {
  font-size: 20px;
  cursor: pointer;
}
.main-buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-buttons-style {
  background-color: #ff7a00;
  width: 80%;
  padding: 7px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 15px;
  height: 54px !important;
}
.iconDelPromo {
  font-size: 18px;
  cursor: pointer;
}
.checkOutCard {
  /*filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));*/
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  elevation: below;
}
.flexContainer {
  /*display :flex;*/
  align-items: center;
}
.upperCard {
  background-color: white;
  border: 2px solid #cccbcb !important;
  border-radius: 10px !important;
  height: auto;
}

.isradio {
  border: 2px solid #1468ba !important;
  background-color: rgba(20, 104, 186, 0.05) !important;
}
.noradio {
  background-color: white;
}
/*.upperCard:active{*/
/*  border: 2px solid #1468BA;*/
/*  background-color: rgba(20, 104, 186, 0.05);*/
/*}*/
.checkHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.01em;
}
.rightDiv {
  float: right;
}
.arrows_top_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  padding-bottom: 10px;
}
.arrows_style {
  position: absolute;
  left: 0%;
  /*justify-content: flex-start!important;*/
  /*align-items: flex-start;*/
}
.chex_wrapper_main_text {
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;

  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.transaction-screen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
  padding: 10px;
}
.header-icon {
  color: #2584e0;
  width: 20px;
  height: 32px;
}
.header-text {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.transaction-payment {
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-font: Poppins;
  color: #ff7a00;
  margin-bottom: -8px;
}
.transaction-amount {
  font-size: 36px;
  font-font: Poppins;
  font-weight: 600;
}

.checkout-payment-heading {
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  color: black;
}

.amount-detail-style {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f3b55;
  font-family: Inter, Arial;
  font-size: 14px;
}

.checkout-form-container {
  background-color: #fff;
  border-radius: 20px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  width: 375px;
  padding: 20px;
  margin-bottom: 0px;
}
.input-field-name {
  font-size: 16px;
  font-weight: 600 !important;
  color: #3f5162 !important;
  margin-bottom: 5px !important;
  margin-top: 10px;
  font-family: "Poppins" !important;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 1000px;
  padding: 15px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: none;
  border: 1px solid #dde3e8;
  border: 0;
  outline: 0;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
}

.inputtransaction.StripeElement {
  display: block !important;
  margin: 10px 0 20px 0 !important;
  max-width: 1000px !important;
  padding: 10px 14px !important;
  font-size: 1em !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: none !important;
  border: 1px solid #dde3e8 !important;
  outline: 0 !important;
  border-radius: 12px !important;
  background-color: #fff !important;
  width: 100% !important;
}

@media only screen and (max-width: 600px) {
  .checkout-form-container {
    background-color: #fff;
    border-radius: 20px;
    width: 365px;
    /*width:100%;*/
    padding: 20px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .CheckOut-container {
    width: 100%;
  }
  .checkout-form-container {
    background-color: #fff;
    border-radius: 20px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 100%;
    padding: 20px;
    margin-bottom: 0px;
  }
}

.cardholder-input_main {
  font-size: 18px;
  font-family: Poppins;
  box-shadow: none !important;
  background-color: #fff !important;
  color: #1f3b55 !important;
  border: 1px solid #dde3e8 !important;
  font-weight: 500;
  border-radius: 12px;
  padding: 10px 14px !important;
}

.cardholder-input_main::placeholder {
  font-size: 18px !important;
  background-color: #fff !important;
  color: #486288 !important;
}
@media only screen and (min-width: 1360px) {
  .cardholder-input {
    width: 100%;
    /* height: 100% !important; */
  }
}
