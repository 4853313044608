.footerContainer {
  background: #1b1c1f;
  /* margin-top: 60px; */
  padding-top: 50px;
  /*padding-left: 200px;*/
  padding-bottom: 50px;
  color: White;
}
.aboutSection_custom {
  margin-right: 20px;
}
.footerLogoContainer {
  margin-bottom: 30px;
}
.footerAboutDescription {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #ffffff;
}

.headingFooter {
  font-family: Poppins;
  font-style: normal;
  width: 100%;
  font-weight: 600;
  /*background-color: red;*/
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 20px;
  color: #ffffff;
}
.footerMenuList {
  list-style: none;
  float: left;
  margin: 0;
  padding: 0;
}
.footerMenuList li {
  margin-bottom: 10px;
}
.footerMenuList li a {
  color: #808389 !important;
  text-decoration-line: underline;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.footerMenuList li a:hover {
  color: #ff7a00 !important;
}
.textFooter {
  font-size: 14px;

  color: #ffffff;
  margin-bottom: 30px;
}
.iconsContainer {
  margin-bottom: 20px;
}
.iconsContainer a {
  /*background-color: red;*/
  /* padding:20px;*/
  margin-right: 15px;
}
.footerLinks_custom {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left !important;
  /*background-color: red;*/
}
.footerLinks_custom span {
  margin-bottom: 10px;
}
.footerLinks_custom span Link {
  color: #ffffff !important;
}

.footerLinks_custom span:hover {
  color: #ff7a00 !important;
}
.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-left: 50px;
}

.copyrightText {
  background-color: #000000;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .textFooter {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .menuContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 0px;
  }
  .headingFooter {
    text-align: center;
  }
  .aboutSection_custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    margin-bottom: 20px;
    margin-right: 0px;
    /*background-color: red;*/
  }
  .footerMenuList {
    text-align: center;
  }
  .headingFooter {
    font-size: 18px;
  }
  .ContactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .textFooter {
    font-size: 14px;
  }
  .iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .copyrightText {
    text-align: center;
  }
  .footerLinks_custom {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    /*background-color: red;*/
  }
}

.ankr_phone_footer {
  text-decoration: none;
}
.ankr_phone_footer:hover {
  transition: 0.8s;
  color: #ff7a00;
}
