
.badge_box_compo_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 20px;
    box-shadow: 5px 5px 10px #A6B4C8;
    background-color: #fff;
    width: 278px;

    height: 350px;
    text-align: center;
    border-radius: 30px;
    /*border: 0.5px solid #A6B4C8;*/

}
.icon_badge_card_box{
    box-shadow: inset 5px 5px 10px  #CACACA;
    border-radius:150%;
    width: 110px;
    height: 110px;
    border: 1px solid #CACACA;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_badge_card_box img{
    width: 70%;
    padding: 10px;
}


.badge_box_compo_wrapper h3{
    font-size: 20px;
    margin-top: 20px;
    font-family: Poppins;
    color: #000;
    font-weight: bold;

}

.badge_box_compo_wrapper p{
    font-size: 14px;
    font-family: Poppins;

}


@media screen and (max-width: 859px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 200px;

        height: 360px;
        text-align: center;
        border-radius: 30px;


    }

    .icon_badge_card_box{
        box-shadow: inset 5px 5px 10px  #CACACA;
        border-radius: 100%;
        width: 90px;
        height: 90px;
        border: 1px solid #CACACA;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon_badge_card_box img{
        width: 90%;
        padding: 10px;
    }




}


@media screen and (max-width: 643px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 270px;

        height: 300px;
        text-align: center;
        margin: 10px;
        border-radius: 30px;


    }



}


@media screen and (max-width: 439px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 250px;

        height: 350px;
        text-align: center;
        margin: 20px;
        border-radius: 30px;


    }

    .icon_badge_card_box{
        box-shadow: inset 5px 5px 10px  #CACACA;
        border-radius: 100%;
        width: 110px;
        height:110px;
        border: 1px solid #CACACA;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon_badge_card_box img{
        width: 70%;
        padding: 10px;
    }

}

@media screen and (max-width: 263px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 250px;

        height: 390px;
        text-align: center;
        margin: 20px;
        border-radius: 30px;


    }


}
@media screen and (max-width: 215px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 250px;

        height: 439px;
        text-align: center;
        margin: 20px;
        border-radius: 30px;


    }


}