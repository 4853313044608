.intro-mainBgss {
    background: linear-gradient(182.55deg, rgba(24, 118, 204, 0.1), rgba(0, 27, 81, 1) 115.19%), rgba(25, 26, 27, 0.1);
    position: relative;
    width: 100%;
    height: 100%;
}

.intro-imageBackground-main-reupoad {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: url(../../Assets/scooter.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
@media screen and (min-width: 768px) {
    .intro-imageBackground-main-reupoad{
        background-size: cover;
        background-position: center center;
    }
}
