.review-card-container {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.review-card-container:hover {
  background-color: #438be3;
  color: #ffffff;
}

.review-card-container-blue {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #438be3;
  box-shadow: 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  color: #ffffff;
}

.review-text {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.rec-arrow {
  margin-bottom: 300px;
}

.name-rate-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.reviewer-name {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: -5px;
}

@media screen and (max-width: 517px) {
  .review-card-container {
    padding: 20px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 340px) {
  .review-card-container {
    padding: 20px;
    margin-top: 30px;
  }
  .name-rate-container {
    margin-right: 20px;
  }
}
