.Chex_video_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.titleAbout_new {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  text-align: left;
  color: #1468ba;
}

.new_about_section_main {
  max-width: 1300px;
  margin: 0 auto;
}

.paragraphAbout_new {
  font-size: 18px; /* Adjust font size */
  font-weight: 400;
  text-align: left;
  line-height: 1.6;
  max-width: 100%; /* Adjust width */
  margin: 0 auto;
  /* padding: 0 20px; */
}
.app-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.about-icons-img {
  margin-right: 20px;
}

.store-icon {
  width: 231px;
  height: 68px;
  opacity: 1;
}

/* Video */
.chex-demo-video_custom {
  border-radius: 3%;
  height: 380px;
  width: 674px;
  border: none;
  z-index: 1;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .titleAbout_new {
    font-size: 26px; /* Reduce font size slightly for tablet screens */
    font-weight: 500;
  }
  .chex-demo-video_custom {
    height: auto;
    width: 100%;
  }

  .paragraphAbout_new {
    font-size: 18px; /* Reduce font size slightly for tablet screens */
    padding: 0 15px;
  }

  .store-icon {
    width: 200px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .chex-demo-video_custom {
    width: 100%;
  }

  .paragraphAbout_new {
    font-size: 16px;
    padding: 0 10px;
  }

  .store-icon {
    width: 180px;
    height: 55px;
  }
}

@media only screen and (max-width: 600px) {
  .chex-demo-video_custom {
    width: 90%;
    border: 1px solid blue;
  }

  .paragraphAbout_new {
    font-size: 14px;
    padding: 0 10px;
    text-align: justify;
  }

  .store-icon {
    width: 150px;
    height: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .chex-demo-video_custom {
    width: 90%;
    border: 1px solid transparent;
  }

  .store-icon {
    width: 130px;
    height: 45px;
  }
  .paragraphAbout_new {
    /* font-size: 16px; */
    padding: 0 10px;
  }
}

@media only screen and (max-width: 480px) {
  .chex-demo-video_custom {
    width: 90%;
    margin-left: -5px;
  }

  .store-icon {
    width: 120px;
    height: 40px;
  }

  .app-icons {
    flex-direction: column;
    gap: 20px;
  }
}
