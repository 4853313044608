.why_chex_component_wrapper_outer{
    background-color: #ffffff;
    /*border: 1px solid black;*/
    padding: 50px;


}

.why_chex_heading{
    text-align: center;

}
.why_chex_heading h2{
    font-size: 42px;
    font-family: Poppins;
    font-weight: bold;
    color: #1B1C1F;

}

.why_chex_heading .span_style_why{
    color: #FF9900;

}

.why_chex_steps_inner_main_wrapper{
    padding: 120px;
    background-image: url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470817/Vectorlogo_1_drg0is_ki4kh8.png');
    background-repeat: no-repeat;
    background-size: contain;
    /*background-color: red;*/

}

.why_chex_step_straight_wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding: 50px;
    /*background-color: green;*/

}
.why_chex_step_straight_wrapper p{
    font-size: 22px;
    font-family: Poppins;
    font-weight: 400;
    color: #1B1C1F;
    padding-left: 300px;

}

.why_chex_step_straight_wrapper img{
    width: 28%;
    padding: 0%;
}

.why_chex_step_reverse_wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.why_chex_step_reverse_wrapper img{
    width: 25%;
    padding: 0%;
}

.why_chex_step_reverse_wrapper p{
    font-size: 22px;
    font-family: Poppins;
    font-weight: 400;
    color: #1B1C1F;
    padding-right: 300px;
}


@media screen and (max-width: 1190px){
    .why_chex_step_straight_wrapper p{
        font-size: 22px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-left: 200px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 22px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 200px;
    }

}

@media screen and (max-width: 1000px){
    .why_chex_steps_inner_main_wrapper{
        padding: 50px;
        background-image: url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470817/Vectorlogo_1_drg0is_ki4kh8.png');
        background-repeat: no-repeat;
        background-size: contain;
        /*background-color: red;*/

    }

    .why_chex_step_straight_wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 30px;
        /*background-color: green;*/

    }


    .why_chex_step_straight_wrapper img{
        width: 35%;
        padding: 0%;
    }

    .why_chex_step_reverse_wrapper img{
        width: 29%;
        padding: 0%;
    }
    .why_chex_step_straight_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-left: 100px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 100px;
    }

}





@media screen and (max-width: 700px){
    .why_chex_steps_inner_main_wrapper{
        padding: 20px;
        background-image: url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470817/Vectorlogo_1_drg0is_ki4kh8.png');
        background-repeat: no-repeat;
        background-size: contain;
        /*background-color: red;*/

    }
    .why_chex_heading h2{
        font-size: 32px;
        font-family: Poppins;
        font-weight: bold;
        color: #1B1C1F;

    }

    .why_chex_step_straight_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px;

        /*background-color: green;*/

    }


    .why_chex_step_reverse_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }


    .why_chex_step_straight_wrapper img{
        width: 55%;
        padding: 0%;
    }

    .why_chex_step_reverse_wrapper img{
        width: 45%;
        padding: 0%;
    }
    .why_chex_step_straight_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-top: 30px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 0px;
        text-align: center;
        padding-top: 30px;
    }

}





@media screen and (max-width: 472px){
    .why_chex_component_wrapper_outer{
        background-color: #ffffff;
        /*border: 1px solid black;*/
        padding: 30px;


    }

    .why_chex_steps_inner_main_wrapper{
        padding: 0px;
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover;
        /*background-color: red;*/

    }

    .why_chex_step_straight_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        /*background-color: green;*/

    }


    .why_chex_step_reverse_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }


    .why_chex_step_straight_wrapper img{
        width: 55%;
        padding: 0%;
    }

    .why_chex_step_reverse_wrapper img{
        width: 45%;
        padding: 0%;
    }
    .why_chex_step_straight_wrapper p{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-top: 30px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 0px;
        text-align: center;
        padding-top: 30px;
    }

}



@media screen and (max-width: 400px) {


    .why_chex_heading h2 {
        font-size: 27px;
        font-family: Poppins;
        font-weight: bold;
        color: #1B1C1F;

    }
}
