.open-tab-bg_color {
  background: #ffffff;
  border-radius: 3px;
  padding: 10px;
  /* margin-top: 10px; */
}
.open-tab-container {
  cursor: pointer;
  padding: 3px 0px 5px 5px ;
  margin-top: 8px;
  margin-right:7px;
  box-shadow:0 0 4px silver;
  border:1px solid white;
  border-radius: 4px;

}
@media only screen and (max-width: 550px) {
  .open-tab-container {
  margin-right: 5px;
  }
}
@media only screen and (min-width: 1024px) {
  .open-tab-container {
  margin-right: 25px;
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 3px !important;
}
.arrow-icon-container {
  border: 2px solid #FF0000;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.arrow-icon-container-inreview{
  border: 2px solid #FF7A00;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.tab-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-text {
  color: #1468ba;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.tabs-content-bg-color {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px;
  height: 650px;
  /* overflow-y: scroll; */
}

/* @media only screen and (max-width: 550px) {
  .tabs-content-bg-color {
    height: 560px;
  }
} */
@media only screen and (min-width: 550px) {
  .tabs-content-bg-color {
   border-radius: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .tabs-content-bg-color {
    width: 335px;

  }
}


.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: unset !important;
}



/* width */
::-webkit-scrollbar {
  width: 3px;
  margin-top: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(231, 231, 231); */
  border-radius: 10px;
  margin-top: 14px;
  margin-bottom: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 10px;
}

.draft-message-show {
  font-weight: 900;
  color: #1467B8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}

.draft-image-show {
  margin-top: 35px;
  width: 231.8px;
  height: 278px;
}
.content-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
 .tab-content{
  color: #1260AF !important; 
  font-size: 11px;
  font-weight: 600;
  border:1px solid #1261B0 !important;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;

}
.tab-content:focus{
  color: #ffffff !important; 
  font-size: 11px !important;
  font-weight: 500 !important;
  background-color: #FF8F00 !important;
  border:1px solid #FF8F00 !important;
  border-radius: 50px !important;
  text-align: center !important;
  padding: 3px 15px !important;
}
/* .ant-tabs-tab-btn:focus{
  color: #ffffff !important; 
  font-size: 11px !important;
  font-weight: 500 !important;
  background-color: #FF8F00 !important;
  border:1px solid #FF8F00 !important;
  border-radius: 50px !important;
  text-align: center !important;
  padding: 3px 18px !important;
} */
.tab-content:hover{
  color: #ffffff !important; 
  font-size: 11px;
  font-weight: 500;
  background-color: #FF8F00 !important;
  border:1px solid #FF8F00 !important;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
}



/* .tab-content:hover{
  color: #ffffff; 
  font-size: 11px;
  font-weight: 500;
  background-color: #FF8F00;
  border:1px solid #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
} */

/* .tab-content:focus{
  color: #ffffff; 
  font-size: 11px;
  font-weight: 500;
  background-color: #FF8F00;
  border:1px solid #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
} */
.ant-tabs-ink-bar {
  display: none;
}

.text-reviewed{
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
}
.reviewed-content-container{
  background-color: white !important;
  margin: 15px !important;
  margin-left: -10px !important;
  border:1px solid rgb(245, 245, 245) !important;
  margin-top: 12px !important;

mix-blend-mode: normal !important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
border-radius: 3px !important;
}

@media only screen and (max-width:1020px){
  .reviewed-content-container{
    margin-left: 0px !important;
    margin: 8px !important;
    margin-top: 12px !important;
  }
}

.document-container{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-top: 10px;
  cursor: pointer;
}
.document-text{
font-size: 13px;
color:#1468ba;
margin-left: 10px;
font-weight: 600;

}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  text-shadow: none !important;
} 
.ant-tabs-content-holder{
  height: 500px !important;
}
