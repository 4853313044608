.inspection-conatiner {
  background: #fff;
  width: 375px;
  height: calc(100vh - 66px);
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden !important;
}

/* @media only screen and (max-width:1440px){
  .inspection-conatiner {
   height: calc(100vh - 66px);
  }
} */
/* @media only screen and (max-width:768px){
  .inspection-conatiner {
   height: 536px;
  }
} */

@media screen and (max-width: 450px) {
  .inspection-conatiner {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.inspections-in-progress-text {
  padding: 15px;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 27px;
  letter-spacing: 0em;
}

.inspection-tag {
  color: #ff9900;
  text-align: center;
  padding: 4px;
  background-color: #fff5e5;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  width: 99.5%;
}

.inspection-button {
  margin-top: 10px;
  padding: 0px 30px;
  width: 160px;
  height: 39px;
  background: #1468ba;
  border-radius: 5px;
  border: 1px solid #1468ba;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

.inspection-main-container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%),
    #191a1b;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden !important;
}

@media only screen and (max-width: 450px) {
  .inspection-main-container {
    display: block;
  }
}

.start-inspection-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  width: 200px;
  cursor: pointer;
  /* position: fixed; */
  /* bottom: 45px; */
  align-self: center;
  margin-top: 20px;
}

.inspection-bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0px;
}

@media only screen and (min-width: 500px) {
  .inspection-bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: unset;
  }
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.all-tiles {
  overflow-y: scroll;
  height: calc(100vh - 385px);
}

.inspection-progress-all-tiles {
  overflow-y: scroll;
  height: calc(100vh - 370px);
  overflow-x: hidden;
}

.certificate-all-tiles {
  overflow-y: scroll;
  height: calc(100vh - 400px);
}
