.key-diff-main {
  width: 100%;
  background-color: #ffffff;
  padding: 50px 0;
}

.key-diff-section {
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
}

.diff-image-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  max-width: 100%;
  margin-right: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.diff-image-section img {
  max-width: 100%;
  height: auto;
}

.diff-text-section {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.diff-heading {
  font-size: 35px;
  font-weight: 500;
  line-height: 61.2px;
  color: #1467b8;
  text-transform: uppercase;
}

.diff-points {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.diff-point {
  display: flex; /* Use flexbox to align icon and text */
  align-items: start; /* Align items at the start */
  font-size: 22px;
  font-weight: 400;
  color: #1b1c1f;
  gap: 5px;
  margin: 4px 0px;
}

.diff-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-top: 10px;
}

@media screen and (max-width: 1200px) {
  .diff-heading {
    font-size: 40px;
  }
  .diff-point {
    font-size: 20px;
  }
  .diff-image-section {
    width: 600px;
    height: auto;
  }
  .diff-icon {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 1030px) {
  .diff-heading {
    font-size: 35px;
  }
  .diff-point {
    font-size: 18px;
  }
  .diff-image-section {
    width: 500px;
    height: auto;
  }
  .diff-icon {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .key-diff-section {
    flex-wrap: wrap;
  }

  .diff-image-section {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .diff-text-section {
    width: 100%; /* Ensure text also takes full width */
  }

  .diff-icon {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 767px) {
  .key-diff-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .diff-image-section {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .diff-heading {
    font-size: 30px;
  }
  .diff-point {
    font-size: 16px;
  }
  .diff-icon {
    width: 14px;
    height: 14px;
  }
}

@media screen and (max-width: 720px) {
  .diff-heading {
    font-size: 28px;
  }
  .diff-point {
    font-size: 15px;
  }
  .diff-icon {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 600px) {
  .diff-heading {
    font-size: 26px;
  }
  .diff-point {
    font-size: 14px;
  }
  .diff-icon {
    width: 10px;
    height: 10px;
  }
}

@media screen and (max-width: 550px) {
  .diff-heading {
    font-size: 24px;
  }
  .diff-point {
    font-size: 13px;
  }
  .diff-icon {
    width: 8px;
    height: 8px;
  }
}

@media screen and (max-width: 500px) {
  .diff-heading {
    font-size: 26px;
  }
  .diff-point {
    font-size: 14px;
  }
  .diff-icon {
    margin-top: 5px;
    margin-right: 5px;
  }
}

/* @media screen and (max-width: 400px) {
  .diff-heading {
    font-size: 20px;
  }
  .diff-point {
    font-size: 11px;
  }
}

@media screen and (max-width: 360px) {
  .diff-heading {
    font-size: 18px;
  }
  .diff-point {
    font-size: 10px;
  }
} */
