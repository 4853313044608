.vec-inspection-inspection_card {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 2px dashed #c9d4e4;
  justify-content: center;
}

.vec-inspection-camera_icon {
  padding: 5px;
  display: none;
}

.veh-inspection-downloadicon_center {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 18px;
  margin-top: 18px;
}

.veh-inspection-download_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7eff8;
  width: 40px;
  flex-direction: column;
  height: 40px;
  border-radius: 60%;
  /* margin-bottom: 20px; */
}

.veh-inspection-registrationcard_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #2a2a2a;
  margin-bottom: 0px;
  margin-top: 8px;
}

.veh-inspection-photo_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #1262b1;
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.inspection-image {
  width: 100%;
  height: 103px;
  border-radius: 10px;
  
  object-fit: initial;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 14px !important;
}

.inspection-card-close_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #FF7A00;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}