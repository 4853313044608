.intro-main-container{
    width: 100%;
}
.intro-imageBackground-main{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: url(../../Assets//bg_img.jpg);
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
} 
  @media screen and (min-width: 768px) {
    .intro-imageBackground-main {
      background-size: cover;
      background-position: center center;
    }
}
.intro-mainBgs {
    background: linear-gradient(182.55deg, rgba(24, 118, 204, 0.1), rgba(0, 27, 81, 1) 115.19%), rgba(25, 26, 27, 0.1);
    position: relative;
    width: 100%;
    height: 100%;
}
.intro-contentMain-screen{
  width: 100%;
}
.intro-headingH5{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #74b3fb;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0px;
}
.intro-headingH1{
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 86px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    padding-bottom: 0px;
    margin: 0px;
    width: 100%;
}
.intro-headingSpan {
  color: #ff9900;
}
.intro-contentP {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}
.intro-content-footer-area{
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.intro-MtB5 {
  /* margin-bottom: 42px; */
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-signinBtn{
    background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
    border-radius: 100px;
    border: 1px solid #ff7400;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    padding: 8px 60px 15px;
    text-decoration: none;
    width: 260px;
}
@media screen and (max-width: 600px) {
    .intro-signinBtn{
      width: 80%;
    }
}
.intro-contentDiv-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}
.intro-signUpWith-email{
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: none;
  padding: 12px 52px;
  text-decoration: none;
  width: 260px;
}
@media screen and (max-width: 600px) {
    .intro-signUpWith-email{
      width: 80%;
    }
}
.intro-signUpWith-email:active{
    outline: none;
    box-shadow: 0 0 0 0 !important;
}
.intro-signUpWith-email:focus {
    outline: none;
    box-shadow: 0 0 0 0 !important;
}