.veh-inspection-chexai_text {
  /* font-family: Poppins; */
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.vec-inspection-top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}
.inspection--card-close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #FF7A00;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.veh-inspection-mobilepaddind {
  text-align: center;
}

.veh-inspection-verification_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
  margin-left: -30px !important;
}

.veh-inspection-verification__formtext {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  margin-left: -30px !important;
}
.veh-inspection-cards_container {
  display: flex;
  /* align-items: center; */
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;
}

.veh-inspection-first_card {
  width: 45%;
  padding: 10px;
}

.veh-inspection-second_card {
  width: 48%;
}

.vec-inspection-submit_button {
  background-color: #ff7a00;
  width: 80%;
  padding: 7px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 15px;
  height: 54px !important;
}

.vec-inspection-Confirm__button {
  background-color: #ff7a00;
  width: 30%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 19px;
 margin-left: 5%;
  
}
.vec-inspection-Confirm__ {
  background-color: #ff7a00;
  width: 40%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 19px;
 margin-left: 29%;
  /* height: 23px !important; */
}

.vec-inspection-Edit__button {
  background-color: #0c559a;
  width: 30%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 19px;
 margin-left: 30%;
  /* height: 23px !important; */
}

.vec-inspection-submitbtn_container {
  padding: 30px;
  text-align: center;
}

/* .ant-collapse {
  border-bottom: 6px solid red;
} */
.ant-collapse .ant-collapse-item {
  border-bottom: 7px solid white !important;
}
.ant-input[disabled] {
  color: #555555; /* Darker text color (adjust the color code as per your preference) */
}

.header-min-width {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.header-max-width {
  width: 32%;
}

.vehicle-inspection-content-container {
  background-color: white !important;
  border-radius: 5px !important;
  margin: 15px !important;
}

.collapse-number-container {
  background-color: #e7eff8 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1468ba;
  border-radius: 4px;
}

.collapse-completed-number-container {
  background-color: #20C18D !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 4px;
}

.vehicle-inspection-collapse-style .ant-collapse.ant-collapse-icon-position-right.vehicle-inspection-content-container {
  border: none !important;
  box-shadow: 0px 10px 30px 0px #0000001A;

}

@media only screen and (max-width: 1024px) {
  .header-max-width {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .header-max-width {
    width: 93%;
  }
}

@media only screen and (max-width: 600px) {
  .header-max-width {
    width: 100%;
  }
}

.itsTXQ {
  border-bottom-left-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.fhkuzc {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B !important;
}

.jeqjDl {
  background: linear-gradient(182.55deg, #1876CC -200%, #001B51 445%), #191A1B !important;
}

.gQYBcx {
  background: linear-gradient(182.55deg, #1876CC -200%, #001B51 445%), #191A1B !important;
}

.kGlcxo {
  background: linear-gradient(182.55deg, #1876CC -200%, #001B51 445%), #191A1B !important;
}