.full-width-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.centered-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.ourAiBasedSection {
  font-size: 35px;
  font-weight: 500;
  line-height: 60.51px;
  text-align: center;
}

.aboutChexButton {
  width: 176px;
  height: 50px;
  margin-top: 20px;
  gap: 10px;
  border-radius: 6px;
  background: #f68b1f;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  .centered-section {
    width: 100%;
    padding: 10px;
  }
  .ourAiBasedSection {
    font-size: 35px;
    font-weight: 400;
    line-height: 50px;
  }
  .aboutChexButton {
    width: 150px;
    height: 45px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1030px) {
  .ourAiBasedSection {
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
  }
  .aboutChexButton {
    width: 140px;
    height: 40px;
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .ourAiBasedSection {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
  }
  .aboutChexButton {
    width: 130px;
    height: 35px;
    font-size: 12px;
  }
}

@media screen and (max-width: 720px) {
  .ourAiBasedSection {
    font-size: 23px;
    font-weight: 400;
    line-height: 38px;
  }
  .aboutChexButton {
    width: 120px;
    height: 30px;
    font-size: 11px;
  }
}

@media screen and (max-width: 600px) {
  .ourAiBasedSection {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
  }
  .aboutChexButton {
    width: 110px;
    height: 28px;
    font-size: 10px;
  }
}

@media screen and (max-width: 550px) {
  .ourAiBasedSection {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  .aboutChexButton {
    width: 100px;
    height: 25px;
    font-size: 9px;
  }
}

@media screen and (max-width: 500px) {
  .ourAiBasedSection {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  .aboutChexButton {
    width: 90px;
    height: 22px;
    font-size: 8px;
  }
}

@media screen and (max-width: 400px) {
  .ourAiBasedSection {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  .aboutChexButton {
    width: 80px;
    height: 20px;
    font-size: 7px;
  }
}

@media screen and (max-width: 360px) {
  .ourAiBasedSection {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .aboutChexButton {
    width: 70px;
    height: 18px;
    font-size: 6px;
  }
}
