.contact-us-container {
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; */
  /* width: 30%; */
  position: relative;
  width: 100%;
  /* height: 821px; */
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; */
}
.contact-us-main_container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b;
  /* height: 100%; */
  height: 100%;
  position: absolute;
  width: 100%;
  background-size: cover;
}
.contact-us-screen_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 3%;
  padding: 10px;
}
.header-icon {
  color: #2584e0;
  width: 20px;
  height: 32px;
}
.header-text {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.contact-us-form_container {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px 0px 20px 0px;
  margin-bottom: 0;
}
.contact-us-text {
  /* font-family: Gotham Pro; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}
.input-fields-width {
  text-align: center;
  width: 95%;
  margin-left: 3%;
}
.text-area-field {
  text-align: center;
  /* margin-left: -8px; */
  margin-bottom: 15px;
}
.text-area-styling {
  background: rgb(231, 238, 245) !important;
  border: 1px solid rgb(231, 239, 248) !important;
  width: 95%;
  border-radius: 5px;
  padding-left: 8px;
  padding-top: 16px;
  font-family: 'Poppins' !important;
}
.text-area-styling::placeholder {
  color: rgb(185, 179, 179);
  font-size: 17px;
  font-family: 'Poppins' !important;
}
.text-area-field :focus {
  border: 1px solid rgb(231, 239, 248) !important;
  outline: none;
  font-family: 'Poppins' !important;
}
.directlt-reach-text {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d2d2d;
  margin-top: 20px;
}
.or-reach-text {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.or-line{
  width: 70%;
  height: 1px;
  background-color: black;
  margin-left: 20px;
}
.email-container {
  display: flex;
  justify-content: center;
}
.email-text {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  color: #ff7a00;
  /* margin-left: 30px; */
}
.contact-us-submit-btn {
  background: #ff7a00 !important;
  border: none !important;
  outline: none !important;
  width: 90% !important;
}
@media only screen and (max-width: 700px) {
  .contact-us-container {
    width: 90%;
  }
  .contact-us-main_container {
    display: flex;
    justify-content: center;
    height: 900px;
  }
}
@media only screen and (min-width: 700px) {
  .contact-us-container {
    width: 40%;
  }
  .contact-us-main_container {
    display: flex;
    justify-content: center;
    height: 900px;
  }
}
