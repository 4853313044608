.commpunity-section {
  background-color: #0050a3;
  max-height: 283px;
  padding: 45px 0;
}

.commpunity-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  padding: 0 20px;
  max-width: 1300px;
}

.commpunity-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 61.2px;
  text-align: center;
  width: 100%;
  max-width: 1081px;
  height: auto;
  padding-top: 10px;
  text-transform: uppercase;
}

.community-numbers {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
  height: auto;
  padding-top: 20px;
}

.community-number-values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%;
  max-width: 130px; */
}

.numbers-value {
  font-size: 35px;
  font-weight: 600;
  line-height: 53.6px;
  text-align: left;
}

.text-value {
  font-size: 15px;
  font-weight: 400;
  /* line-height: 24.12px; */
  text-align: left;
}

/* Media Queries */

@media screen and (max-width: 1200px) {
  .commpunity-title {
    font-size: 28px;
    font-weight: 300;
  }

  .numbers-value {
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
  }

  .text-value {
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
  }
}

@media screen and (max-width: 1030px) {
  .commpunity-title {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
  }

  .numbers-value {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
  }

  .text-value {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .commpunity-title {
    font-size: 26px;
  }

  .numbers-value {
    font-size: 27px;
    font-weight: 450;
    line-height: 40px;
  }

  .text-value {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media screen and (max-width: 720px) {
  .commpunity-container {
    max-width: 90%;
  }

  .commpunity-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
  }

  .numbers-value {
    font-size: 26px;
    font-weight: 450;
    line-height: 38px;
  }

  .text-value {
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .commpunity-container {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .commpunity-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 20px;
  }

  .numbers-value {
    font-size: 22px;
    font-weight: 450;
    line-height: 36px;
  }

  .text-value {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }

  .community-number-values {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 550px) {
  .commpunity-title {
    font-size: 26px;
    font-weight: 400;
    line-height: 35px;
  }

  .numbers-value {
    font-size: 21px;
    font-weight: 450;
    line-height: 32px;
  }

  .text-value {
    font-size: 11px;
    font-weight: 300;
    line-height: 15px;
  }
}

@media screen and (max-width: 500px) {
  .commpunity-title {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  }

  /* .numbers-value {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  } */

  .text-value {
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
  }
}

@media screen and (max-width: 400px) {
  .commpunity-title {
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
  }

  /* .numbers-value {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  } */

  .text-value {
    font-size: 9px;
    font-weight: 300;
    line-height: 12px;
  }
}

@media screen and (max-width: 360px) {
  .commpunity-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  /* .numbers-value {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  } */

  .text-value {
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
  }
}
