.submitted-conatiner {
  background: #fff;
  width: 375px;
  height: calc(100vh - 79px);
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}


@media screen and (max-width: 450px) {
  .submitted-conatiner {
    width: 100%;
  }
}

/*
  @media screen and (max-width: 385px) {
    .submitted-conatiner {
      height: 645px;
    
     
    }
  } */


.submitteds-in-progress-text {
  padding: 15px;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.submitted-tag {
  color: #ff9900;
  text-align: center;
  padding: 4px 30px;
  background-color: #fff5e5;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  width: 99.5%;

}


.submitted-button {

  margin-top: 10px;
  padding: 0px 30px;
  width: 160px;
  height: 39px;
  background: #1468BA;
  border-radius: 5px;
  border: 1px solid #1468BA;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

.submitted-main-container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden !important;
}

@media only screen and (max-width:450px) {
  .submitted-main-container {
    display: block;
  }
}


.submitted-bottom-content {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.arrow-icon-container-submitted {
  border: 2px solid #FF7A00;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  top: 25px;
  right: 30px;
}

.submitted-tile-tag {
  border: 1px solid #20C18D;
  background-color: #20C18D;
  background: cover;
  /* padding: 0px 20px; */
  position: absolute;
  right: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #fff;
  width: 69px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  margin-right: -1px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 35px 2px !important;
  border-bottom: none !important;

}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  display: inline-block !important;
}

.submitted-tile-tag-in-review {
  border: 1px solid #1890ff;
  background-color: #1890ff;
  background: cover;
  /* padding: 0px 20px; */
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #fff;
  width: 74px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  margin-right: -1px;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.check-inspection-text {
  width: 70%;
  text-align: center;
  color: #1467B8;
  font-size: 15px;
}