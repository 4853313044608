.form_section_main_outer{
    padding: 50px;
    background-color: #FAFAFA;


}
.form_section_main_inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.form_section_image_wrapper{
    width: 100%;

}
.form_section_image_wrapper img{
    width: 80%;

}
.form_section_content_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 40px;
    align-items: flex-start;
    /*background-color: rgba(255, 0, 0, 0.11);*/
}
.form_section_content_wrapper .form_heading_title{
    font-size: 28px;
    padding-bottom: 20px;
    font-weight: bold;
    font-family: Poppins;
    color: #000000;

}

.form_section_content_wrapper .form_heading_title span{
    color: #FF9900;
}


.namingSections_chex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input_field_chex_ride {
    background: rgb(255, 255, 255) !important;
    border: 1px solid #C1C1C1 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
    margin: 0px auto 15px !important;
    padding: 15px 8px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: 'Poppins' !important;
    letter-spacing: 1px !important;

}

::placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

.terms_conditionsContainer{
    display: flex;
    flex-direction: row;
}

.termsConditionsText{
    padding-left: 10px;
    font-size: 16px;
}

.button-wrapper_chex_ride {
    background:  #FF9900;
    border-radius: 5px !important;
    border: 1px solid #FF9900 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    /*line-height: 32px !important;*/
    text-align: center !important;
    color: #ffffff;

    text-decoration: none !important;
    cursor: pointer !important;
    display: block;
    width: 100% !important;
    margin: 0px auto !important;
    height: 44px !important;
}
.button-wrapper_chex_ride:hover{
    background-color: #ffffff;
    border: 1px solid #FF9900;
    color: #FF9900;


}
.button-wrapper_chex_ride:active{
    color: #FF9900!important;
    border: 1px solid #FF9900;
}



.newcheckmark_chex_ride {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e3e2e2;
    border:1px solid grey;
    border-radius: 3px;
}

.register-checkbox-text_chex_ride{
    color:black;
    padding-left: 4px;
}
.newcontainer_ride {
    /*text-align: center;*/
    /*position: relative;*/
    /*padding-left: 35px;*/
    /*margin-bottom: 12px;*/
    /*width: 20px;*/
    /*height: 20px;*/
    /*cursor: pointer;*/
    /*-webkit-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
}
.newcontainer:hover input ~ .newcheckmark_chex_ride {
    background-color: #ccc!important;

}
.newcontainer input:checked ~ .newcheckmark_chex_ride {
    background-color: #fff;

}
.newcheckmark_chex_ride:after {
    content: '';
    position: absolute;
    display: none;
    height: 18px!important;
    width: 18px;
    border-radius: 2px;
    border:1px solid transparent;
    background-color: #d3d3d3;

}
.newcontainer input:checked ~ .newcheckmark_chex_ride:after {
    content: '\2713';
    display: block;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    /*margin-top: -10px;*/

    text-align: center;

    color: #ff7a00;
}


@media screen and (max-width: 1799px) {
    .form-item-style-chex_first_ride{
        width: 48%;
    }
}

@media screen and (max-width: 842px) {
    .form-item-style-chex_first_ride {
        width: 100%;
    }

    .namingSections_chex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}

@media screen and (max-width: 510px){
    .register-checkbox-text_chex_ride{
        font-size: 12px;
    }

}



@media screen and (min-width: 1800px) {
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.login-landing-signup_ride {
    display: "flex";
    margin-top: 15px;
}

@media screen and (min-width: 1440px) {
    .login-landing-signup_ride {
        display: "flex";
        margin-top: 3px;
    }
}



@media screen and (max-width:1020px) {
    .form_section_image_wrapper{
        width: 100%;

    }
    .form_section_image_wrapper img{
        width: 100%;

    }


}


@media screen and (max-width:820px) {
    .form_section_main_outer{
        /*background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662631072/Group_423_v53nfc.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: contain;*/
        padding:0px;
    }
    .form_section_main_inner{
        background-color: rgba(236, 236, 236, 0.7);
        padding: 30px;
    }
    .form_section_image_wrapper{
        width: 100%;
        display: none;

    }
    .form_section_content_wrapper{

    }


    .form_section_content_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        align-items: flex-start;
        /*background-color: rgba(255, 0, 0, 0.11);*/
    }
    .form_section_content_wrapper .form_heading_title{
        font-size: 28px;
        padding-bottom: 20px;
        font-weight: bold;
        font-family: Poppins;
        color: #000000;

    }


}

@media screen and (max-width: 600px) {
.form_section_content_wrapper{
    padding-left: 0px;
}
    
}

@media screen and (max-width: 380px) {
    .form_section_main_inner{
        padding: 10px;
    }

    .form_section_content_wrapper .form_heading_title{
        font-size: 20px;
    }

}





/*pricing wrapper ride start*/

.pricing_wrapper_main_ride{
    padding: 50px;
    background-color: #FAFAFA;
}
.pricing_wrapper_main_ride_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /*height: 400px;*/
}
.pricing_wrapper_main_ride_inner h2{
    font-size: 40px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
}
.pricing_wrapper_main_ride_inner p{
    font-size: 20px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
}

.pricing_wrapper_main_ride_inner .btn_pricing_ride{
    background-color: #1468BA;
    margin-top: 20px!important;
    color: #ffffff;
    border: 1px solid #1468BA;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.pricing_wrapper_main_ride_inner .btn_pricing_ride:hover{
    background-color: #ffffff;
    color: #1468BA;
    border: 1px solid #1468BA;
    transition: 0.6s;
}

.points_pricing_wrapper_main{
    border: 1px solid #FF9900;
    border-radius: 5px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    /*background-color: red;*/
    padding: 10px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px !important;
}
.points_pricing_wrapper_inner{
    /*display: flex;*/
    /*width: 100%;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
    /*flex-direction: row;*/
    /*background-color: red;*/
}
.points_pricing_wrapper_inner ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*background-color: red;*/
    padding-left:0px;

}
.points_pricing_wrapper_inner ul li{
    list-style: none;
}



.point_wrapper_ride_style{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

}

.point_wrapper_ride_style p{
    font-size: 16px;
    font-family: Poppins;
    color: #000000;

}
.point_wrapper_ride_style p span{
    color: #FF7A00;
    font-weight: bold;

}


.pricing_card_main_outer_ride{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 65%;

}

.pricing_cards_main_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}



@media screen and (max-width: 1139px) {
    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 16px;

    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 85%;

    }

    
}




@media screen and (max-width: 960px) {
    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 16px;

    }
    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-right: 20px;
        margin-bottom: 20px !important;
    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 95%;

    }

}

@media screen and (max-width: 752px) {
    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-right: 20px;
        margin-bottom: 20px !important;
    }


}


@media screen and (max-width: 713px) {
    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;

    }

}



@media screen and (max-width: 691px) {

    .pricing_cards_main_wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

    }
    .second_lists_inspection_style{
        margin-top: -15px;
    }
    .pricing_cards_main_wrapper .pricing_card_box_wrap{
        padding: 20px;
    }

    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 14px;

    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 98%;

    }

    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 98%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media screen and (max-width: 548px) {
    .pricing_card_main_outer_ride{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 98%;

    }

}


@media screen and (max-width: 422px) {
    .pricing_card_main_outer_ride{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;

    }
    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .points_pricing_wrapper_inner{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
}


@media screen and (max-width: 388px) {
    .pricing_wrapper_main_ride{
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #FAFAFA;
    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
.point_wrapper_ride_style p{
    font-size: 12px;
    text-align: left;
}

}




/*States Works Styling Start */

.states_section_main_wrapper_outer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.image_wrapper_states_cars {
    width: 80%;

}
.image_wrapper_states_cars img{
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 100%;

}


.content_wrapper_states_main{
    padding-left: 40px;
    width: 100%;
}
.content_inner_state_wrapper {
    width: 40%;


}
.content_inner_state_wrapper h2{
    color: #1B1C1F;
    font-size: 32px;
    font-family: Poppins;
    font-weight: bold;
}
.content_inner_state_wrapper h2 .orangeSpan {
    color: #FF9900;

}
.content_inner_state_wrapper h2 .blueSpan {
    color: #1468BA;
}


.content_inner_state_wrapper .states_content_data{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.states_content_data .unorder_lists ul li{
   list-style: none;
    color: #646464;
    font-size: 16px;
}


@media screen and (max-width: 1299px) {
    .content_inner_state_wrapper {
        width: 60%;


    }
    
}

@media screen and (max-width: 895px) {
    .content_inner_state_wrapper {
        width: 70%;


    }

}

@media screen and (max-width: 767px) {
    .content_inner_state_wrapper {
        width: 80%;


    }
    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 28px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 14px;
    }

}


@media screen and (max-width: 640px) {
    .states_section_main_wrapper_outer{
        padding: 20px;
    }
    .image_wrapper_states_cars{
        display: none;
    }
    .content_inner_state_wrapper {
        width: 80%;


    }
    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 26px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 14px;
    }

}


@media screen and (max-width: 398px) {
    .content_inner_state_wrapper {
        width: 100%;


    }
    .content_wrapper_states_main{
        padding-left: 0px;
        width: 100%;
    }

    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 22px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 12px;
    }

}





/*Chex Why Chex Ai wrapper styling start*/

.why_chex_section_ride{
    padding: 50px;

}
.main_wrapper_flex_chex{
    /*background-color: rgba(255, 0, 0, 0.36);*/
}
.why_chex_inner_section_wrapper_ride{

    padding-top: 5%;
    padding-bottom: 5%;

}

.straigt_ride_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.reverse_ride_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
}


.why_chex_content_wrapper_ride{
    position: relative;


}


.reverse_chex_image_wrapper{
    position: absolute;
    top:-100%;
    right:0%;
    padding-left: 30%;
}
.straign_content_image_wrapper{
    position: absolute;
    top:-50%;
    left:-10%;
}
.chex_content_image_wrapper img{
    width: 70%;

}
.content_code_section{

}
.straigt_content_ride{
    padding-right: 30%;
}
.reverse_ride_content{
    padding-left: 30%;

}

.content_code_section h2{
    font-family: Poppins;
    font-size: 38px;
    color: #000000;

}

.content_code_section p{
    font-family: Poppins;
    font-size: 18px;

    color: #000000;

}
.content_code_section p span{

    color: #FF9900;
    font-weight: bold;

}

.why_chex_Image_wrapper_ride img{
    width: 70%;

}

@media screen and (max-width: 1290px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }
    
}

@media screen and (max-width: 1014px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

    .content_code_section h2{
        font-family: Poppins;
        font-size: 34px;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 16px;

        color: #000000;

    }

    .straigt_content_ride{
        padding-right: 20%;
    }
    .reverse_ride_content{
        padding-left: 20%;

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        right:0%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-50%;
        left:-10%;
    }
    .chex_content_image_wrapper img{
        width: 60%;

    }

}


@media screen and (max-width: 915px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

    .content_code_section h2{
        font-family: Poppins;
        font-size: 34px;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 16px;

        color: #000000;

    }

    .straigt_content_ride{
        padding-right: 20%;
    }
    .reverse_ride_content{
        padding-left: 20%;

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        left:-20%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-50%;
        left:-10%;
    }
    .chex_content_image_wrapper img{
        width: 60%;

    }

}




@media screen and (max-width: 757px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 40px;
    }

    .reverse_ride_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .content_code_section h2{
        font-family: Poppins;
        font-size: 34px;
        text-align: center;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 18px;
        text-align: center;

        color: #000000;

    }

    .straigt_content_ride{
        padding-right: 0%;
        padding-top: 10%;
    }
    .reverse_ride_content{
        padding-left: 0%;
        padding-top: 10%;

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        display: none;
        top:-100%;
        left:0%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-100%;
        display: none;
        left:0%;
    }
    .chex_content_image_wrapper img{
        width: 100%;

    }

    .why_chex_Image_wrapper_ride img{
        width: 100%;
        padding: 10%;

    }


}

@media screen and (max-width: 480px) {
    .why_chex_section_ride{
        padding: 20px;

    }
    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        left:0%;
        padding-left: 10%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-10%;
        left:0%;
    }
    .chex_content_image_wrapper img{
        width: 70%;

    }


    .why_chex_Image_wrapper_ride img{
        width: 100%;
        padding: 5%;

    }
    .content_code_section h2{
        font-family: Poppins;
        font-size: 26px;
        text-align: center;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 16px;
        text-align: center;

        color: #000000;

    }
}


@media screen and (max-width: 350px) {
    .why_chex_section_ride{
        padding: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

    }
    .chex_content_image_wrapper img{
        display: none;

    }
    .content_code_section h2{
        font-family: Poppins;
        font-size: 20px;
        text-align: center;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 12px;
        text-align: center;

        color: #000000;

    }


}