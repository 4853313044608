.main_wrapper_help_business_outer{

    padding: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}
.wrapper_reverse_order{
    flex-direction: row-reverse!important;
}

.wrapper_background_style {
    background-color: #FAFAFA;
}
.content_wrapper_help_business{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
    padding-right: 80px;


}

.wrapper_reverse_order .content_wrapper_help_business{
    padding-right: 0px !important;
    padding-left: 70px !important;
}

.content_wrapper_help_business h2{
    font-size: 32px;
    font-weight: bold;



}

.content_wrapper_help_business h2 .business_help_span_style{
    color: #FF9900;

}


.content_wrapper_help_business p{
    font-size: 18px;
    font-weight: 400;
}

.content_wrapper_help_business .btn_contact_wrapper_help_business{
    margin-top: 40px;
}

.content_wrapper_help_business .btn_contact_wrapper_help_business a{
    background-color: #1468BA;
    color: #ffffff;
    border: 1px solid #1468BA;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;

}

.content_wrapper_help_business .btn_contact_wrapper_help_business a:hover{
    background-color: #ffffff;
    /*border: 1px solid #1468BA;*/

    color: #1468BA;
    transition: 0.7s;



}

.image_wrapper_help_business{
    width: 70%;

}
.imageGovtStyle{
    width: 50% !important;
}

.wrapper_reverse_order .image_wrapper_help_business{
    width: 50% !important;
}

.image_wrapper_help_business img{
width: 100%;
    padding: 30px;
}


@media screen and (max-width: 1100px) {
    .main_wrapper_help_business_outer{

        padding: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 60%;
        padding-right: 50px;


    }

    .image_wrapper_help_business{
        width: 60%;

    }
    .imageGovtStyle{
        width: 50% !important;
    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 30px;
    }
    
}



@media screen and (max-width:850px) {
    .main_wrapper_help_business_outer{

        padding: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

    }
    .imageGovtStyle{
        width: 100% !important;
    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 80%;
        padding-right: 40px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 30px;
    }

    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
    }

    .content_wrapper_help_business h2{
        font-size: 24px;
    }

    .content_wrapper_help_business p{
        font-size: 14px;

    }



}



@media screen and (max-width:648px) {
    .main_wrapper_help_business_outer{

        padding: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }
    .wrapper_reverse_order{
        flex-direction:column-reverse!important;
    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        padding-right: 0px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 30px;
    }
    .wrapper_reverse_order .content_wrapper_help_business{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .wrapper_reverse_order .image_wrapper_help_business{
        width: 80% !important;
    }


    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_wrapper_help_business h2{
        font-size: 26px;
        text-align: center;
    }

    .content_wrapper_help_business p{
        font-size: 16px;
        text-align: center;

    }



}







@media screen and (max-width:479px) {
    .main_wrapper_help_business_outer{

        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        padding-right: 0px;
        padding-top: 20px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 0px;
    }

    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_wrapper_help_business h2{
        font-size: 22px;
        text-align: center;
    }

    .content_wrapper_help_business p{
        font-size: 14px;
        text-align: center;

    }



}






@media screen and (max-width:341px) {
    .main_wrapper_help_business_outer{

        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-right: 0px;
        padding-top: 20px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 0px;
    }

    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_wrapper_help_business h2{
        font-size: 18px;
        text-align: center;
    }

    .content_wrapper_help_business p{
        font-size: 12px;
        text-align: center;

    }



}